import React, { createContext, useState, useContext } from 'react';

const AiResultsContext = createContext();

export function AiResultsProvider({ children }) {
  const [aiResults, setAiResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ selectedAiView, setSelectedAiView ] = useState('summary');

  return (
    <AiResultsContext.Provider value={{ aiResults, setAiResults, loading, setLoading, error, setError, selectedAiView, setSelectedAiView }}>
      {children}
    </AiResultsContext.Provider>
  );
}

export function useAiResults() {
  return useContext(AiResultsContext);
}