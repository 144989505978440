import { useState } from "react";
import { Button, Box, FormHelperText, FormControl, FormLabel, Input } from "@mui/joy";
import { useNotes } from "../../contexts/notesContext";

function ViewPrivateItemInput({ content }) {
  const [ password, setPassword ] = useState('');
  const { handlePasswordCheck, passedPasswordCheck } = useNotes();
  console.log("content", content)
  return (
    <Box sx={{ pl: { xs: 1.5, md: 3 }, display: 'flex', flexDirection: 'column', gap: 2, width: { xs: '75%', md: '30%' } }}>
      <FormControl>
        <FormLabel sx={{ mb: 1.75 }}>A password is required to view this item.</FormLabel>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Input type="password" placeholder="Enter password" sx={{ flexGrow: 1 }} value={password} onChange={(e) => setPassword(e.target.value)} />
          <Button size="sm" onClick={() => handlePasswordCheck(password, content)}>Proceed</Button>
        </Box>
      </FormControl>
      { passedPasswordCheck === false && <FormHelperText sx={{ color: 'red' }}>Incorrect password. If you are the owner of this item, you can unlock it by clicking the padlock icon above and then resetting a password.</FormHelperText> }
    </Box>
  )
}

export default ViewPrivateItemInput;