import { useEffect, useState } from "react";
import { Box } from "@mui/joy";
import { fetchConnectionsFromFirestoreByFromId } from "../../../database/connections-db";
import { useNotes } from "../../../contexts/notesContext";
import { useAiResults } from "../../../contexts/aiResultsContext";
import ConnectionCard from "../../ConnectionCard";
import { useParams } from "react-router-dom";
import { Network } from "d3plus-react";
import { BASE_PRIVATEAPP_URL } from "../../../../redirects";

const methods = {
  // sizeMax: 120,
  // linkSize: 2,
  // total: true,
  width: 400,
  height: 400,
  zoom: false,
  label: d => d.title,
  // color: '#000',
  shape: "Rect",
  shapeConfig: {
    width: 150,
    height: 66,
    fill: "#fff",
    state: {
      active: {
        fill: "#f00"
      }
    },
    labelConfig: {
      fontMax: 12,
      fontMin: 12,
      padding: 2,
    }
  }
  // shapeConfig: {
  //   Rect: {
  //     width: 150, // fixed width for rectangle nodes
  //     height: 66, // fixed height for rectangle nodes
  //     borderRadius: 5, // add border radius
  //     // fill: "#fff",
  //     labelConfig: {
  //       // fontColor: "#fff", // set font color for rectangle nodes
  //       fontMax: 14, // set max font size for rectangle nodes
  //       fontMin: 14, // set min font size for rectangle nodes
  //       padding: 2, // set padding for rectangle nodes
  //     }
  //   },
  //   shape: "Rect" // set default shape for nodes
  // }
};

function ConnectionResultsForInlinePane() {
  const { accountId, selectedProjectId } = useParams();
  const { selectedNote } = useNotes();
  const { selectedAiView } = useAiResults();
  const [connections, setConnections] = useState([]);
  const [associations, setAssociations] = useState({ nodes: [], links: [] });
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState([]);

  const fetchConnections = async () => {
    const result = await fetchConnectionsFromFirestoreByFromId({ accountId, project: selectedProjectId, fromId: selectedNote.id })
    setConnections(result.connections)
  }

  async function parseData({ connections }) {
    try {
  
      const nodesMap = new Map();
      const links = [];
  
      connections.forEach(connection => {
        const { fromType, fromId, fromTitle, toType, toId, toTitle, comment, project } = connection;
  
        // Add source node if fromId is defined
        if (fromId && !nodesMap.has(fromId)) {
          nodesMap.set(fromId, { id: fromId, groupBy: fromType, title: fromTitle });
        }
  
        // Add target node if toId is defined
        if (toId && !nodesMap.has(toId)) {
          nodesMap.set(toId, { id: toId, groupBy: toType, title: toTitle });
        }
  
        // Add link if both fromId and toId are defined
        if (fromId && toId) {
          const fromLink = constructUrlAndTitle({ title: fromTitle, type: fromType, id: fromId, project });
          const toLink = constructUrlAndTitle({ title: toTitle, type: toType, id: toId, project });

          console.log("fromLink", fromLink)
          console.log("toLink", toLink)
  
          links.push({ 
            source: fromId, 
            target: toId, 
            comment, 
            fromTitle,
            toTitle,
            fromUrl: fromLink.url,
            toUrl: toLink.url
          });
        }
      });
  
      const nodes = Array.from(nodesMap.values());
  
      return { nodes, links };
    } catch (error) {
      console.error("Error parsing data:", error);
    }
  }

  const parseConnections = async () => {
    const result = await parseData({ connections })
    console.log("parsed data", result)
    setAssociations(result)
  }

  const handleNodeClick = (node) => {
    setSelectedNode(node);
    // Use the selected node.id and check if associations.links contains a matching id in either the link.source or link.target and return the link.comment
    const connections = associations.links.filter(link => link.source === node.id || link.target === node.id);
    setSelectedConnection(connections);
  }

  function constructUrlAndTitle(item) {
    if (!item) return { url: '', title: '' };

    let url = '';
    let title = item.title;

    console.log("item", item)

    switch (item.type) {
        case "note":
        case "searchItem_note":
            url = BASE_PRIVATEAPP_URL + accountId + '/' + item.project + (item.bin ? `/bin?noteId=${item.id}` : `?noteId=${item.id}`);
            break;
        case "searchItem_tagBoard":
        case "tagBoard":
        case "searchItem_boardGroup":
        case "tagGroup":
        case "searchItem_tag":
        case "tag":
            url = BASE_PRIVATEAPP_URL + accountId + '/' + item.project + `/tags/b/` + item.id;
            break;
        case "searchItem_persona":
        case "persona":
            url = BASE_PRIVATEAPP_URL + accountId + '/' + item.project + `/personas?personaId=` + item.id;
            break;
        case "searchItem_journey":
        case "journey":
            url = BASE_PRIVATEAPP_URL + accountId + '/' + item.project + `/journeys?journeyId=` + item.id;
            break;
        case "searchItem_feature":
        case "feature":
            url = BASE_PRIVATEAPP_URL + accountId + '/' + item.project + `/features?featureId=` + item.id;
            break;
        case "searchItem_sitemap":
        case "sitemap":
            url = BASE_PRIVATEAPP_URL + accountId + '/' + item.project + `/ia?iaId=` + item.id;
            break;
        case "searchItem_page":
        case "page":
            url = BASE_PRIVATEAPP_URL + accountId + '/' + item.project + `/pages?pageId=` + item.id;
            break;
        case "searchItem_pageset":
        case "pageset":
            url = BASE_PRIVATEAPP_URL + accountId + '/' + item.project + `/pages?pagesetId=` + item.id;
            break;
        case "url":
            url = "https://";
            break;
        default:
            break;
    }

    return { url, title };
  }

  useEffect(() => {
    fetchConnections()
  }, [selectedAiView])

  useEffect(() => {
    if (connections && connections.length > 0) {
      console.log(connections)
      const result = parseConnections()
    }
  }, [connections])

  useEffect(() => {
    console.log("associations", associations)
  }, [associations])

  return (
    <>
      <Network 
        config={{ 
          ...methods, 
          nodes: associations.nodes, 
          links: associations.links,
          on: {
            // Event listener for node click
            click: handleNodeClick
          }, 
        }} 
      />
      { associations?.links?.length > 0 &&
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: .75 }}>
          {associations.links.map((item, index) => (
            <Box key={index + `c-item`}>
              <ConnectionCard mode="inline" data={item} />
            </Box>
          ))}
        </Box>
      }
    </>
  )
}

export default ConnectionResultsForInlinePane