import React, {useEffect, useState, useRef, useCallback} from 'react';
import { $getSelection, $isRangeSelection, $isRootOrShadowRoot, $isLineBreakNode, $createRangeSelection, $setSelection, $createParagraphNode } from 'lexical';
import {$findMatchingParent, mergeRegister} from '@lexical/utils';
import { getSelectedNode } from '../../../../utils/utils';
import { sanitizeUrl } from '../../../../utils/url';
import { setFloatingElemPositionForLinkEditor } from '../../../../utils/setFloatingElemPositionForLinkEditor';
import { COMMAND_PRIORITY_LOW, KEY_ESCAPE_COMMAND, SELECTION_CHANGE_COMMAND, createCommand } from 'lexical';
import { Link, useNavigate } from 'react-router-dom';
import {
  BaseSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  LexicalEditor,
  $createTextNode,
} from 'lexical';
import { $createCustomLinkNode, $isCustomLinkNode } from '../../nodes/CustomLinkNode';

import Search from '../../../Search';
import { Box, Button, Input, List, ListItem, Tabs, TabList, Tab, TabPanel, Typography, Tooltip, Chip } from '@mui/joy';
import { tabClasses } from '@mui/joy/Tab';
import { set } from 'date-fns';
import { CustomLinkNode } from '../../nodes/CustomLinkNode';
import {
  AssignmentRounded as NoteIcon,
  Label as TagIcon,
  PersonAdd as PersonaIcon,
  Route as JourneyIcon,
  AddReaction as FeatureIcon,
  AccountTree as SitemapIcon,
  Article as PageIcon,
  Web as PagesetIcon,
  Link as ExternalIcon
} from '@mui/icons-material';
// Create a new command for initial link creation
export const UPDATE_INITIAL_LINK_COMMAND = createCommand('UPDATE_INITIAL_LINK_COMMAND');


export default function FloatingSmartLinkEditor({
    editor,
    isLink,
    setIsLink,
    anchorElem,
    isLinkEditMode,
    setIsLinkEditMode,
    linkType,
    setLinkType,
  }) {
    const editorRef = useRef(null);
    const inputRef = useRef(null);
    const [value,setValue] = useState();
    const [linkUrl, setLinkUrl] = useState('');
    const [editedLinkUrl, setEditedLinkUrl] = useState('https://');
    const [linkTitle, setLinkTitle] = useState('');
    const [externalLinkUrl, setExternalLinkUrl] = useState('https://');
    const [lastSelection, setLastSelection] = useState(
      null,
    );

    useEffect(() => {
      console.log("linkType", linkType)
      console.log("linkUrl", linkUrl)
      console.log("linkTitle", linkTitle)
    }, [linkType, linkUrl, linkTitle])
  
    const updateLinkEditor = useCallback(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const node = getSelectedNode(selection);
        const linkParent = $findMatchingParent(node, $isCustomLinkNode);
  
        if (linkParent) {
          setLinkUrl(linkParent.getURL());
          setLinkTitle(linkParent.getTitle());
          setLinkType(linkParent.getLinkType?.() || 'external');
        } else if ($isCustomLinkNode(node)) {
          setLinkTitle(node.getTitle());
          setLinkUrl(node.getURL());
          setLinkType(node.getLinkType?.() || 'external');
        } else {
          setLinkUrl('');
          setLinkType('external');
        }
        if (isLinkEditMode) {
          setEditedLinkUrl(linkUrl);
        }
      }
      const editorElem = editorRef.current;
      const nativeSelection = window.getSelection();
      const activeElement = document.activeElement;
  
      if (editorElem === null) {
        return;
      }
  
      const rootElement = editor.getRootElement();
  
      if (
        selection !== null &&
        nativeSelection !== null &&
        rootElement !== null &&
        rootElement.contains(nativeSelection.anchorNode) &&
        editor.isEditable()
      ) {
        const domRect =
          nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
        if (domRect) {
          domRect.y += 40;
          setFloatingElemPositionForLinkEditor(domRect, editorElem, anchorElem);
        }
        setLastSelection(selection);
      } else if (!activeElement || activeElement.className !== 'link-input') {
        if (rootElement !== null) {
          setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem);
        }
        setLastSelection(null);
        setIsLinkEditMode(false);
        setLinkUrl('');
      }
  
      return true;
    }, [anchorElem, editor, setIsLinkEditMode, isLinkEditMode, linkUrl, setLinkType]);
  
    useEffect(() => {
      const scrollerElem = anchorElem.parentElement;
  
      const update = () => {
        editor.getEditorState().read(() => {
          updateLinkEditor();
        });
      };
  
      window.addEventListener('resize', update);
  
      if (scrollerElem) {
        scrollerElem.addEventListener('scroll', update);
      }
  
      return () => {
        window.removeEventListener('resize', update);
  
        if (scrollerElem) {
          scrollerElem.removeEventListener('scroll', update);
        }
      };
    }, [anchorElem.parentElement, editor, updateLinkEditor]);
  
    useEffect(() => {
      return mergeRegister(
        editor.registerUpdateListener(({editorState}) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }),
  
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateLinkEditor();
            return true;
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          KEY_ESCAPE_COMMAND,
          () => {
            if (isLink) {
              setIsLink(false);
              return true;
            }
            return false;
          },
          COMMAND_PRIORITY_HIGH,
        ),
      );
    }, [editor, updateLinkEditor, setIsLink, isLink]);
  
    useEffect(() => {
      editor.getEditorState().read(() => {
        updateLinkEditor();
      });
    }, [editor, updateLinkEditor]);

    useEffect(() => {
      return editor.registerCommand(
        UPDATE_INITIAL_LINK_COMMAND,
        (payload) => {
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              const { url, title, newLinkType } = payload;
              const sanitizedUrl = sanitizeUrl(url);
              const sanitizedTitle = title || sanitizedUrl;
              
              const node = getSelectedNode(selection);
              const linkParent = $findMatchingParent(node, $isCustomLinkNode);
              
              // If we found an existing link node, update it
              if (linkParent) {
                console.log("linkParent", linkParent)
                // Update existing link node
                const writable = linkParent.getWritable();
                writable.setURL(sanitizedUrl);
                writable.__title = sanitizedTitle;
                writable.__linkType = newLinkType || 'external';
                setLinkType(newLinkType || 'external');
                
                // Update the text content if needed
                const textContent = selection.getTextContent() || sanitizedTitle;
                const textNode = linkParent.getFirstChild();
                if (textNode) {
                  textNode.setTextContent(textContent);
                }
              } else if ($isCustomLinkNode(node)) {
                // Update the node directly if it's a link node
                const writable = node.getWritable();
                writable.setURL(sanitizedUrl);
                writable.__title = sanitizedTitle;
                writable.__linkType = newLinkType || 'external';
                
                // Update the text content if needed
                const textContent = selection.getTextContent() || sanitizedTitle;
                const textNode = node.getFirstChild();
                if (textNode) {
                  textNode.setTextContent(textContent);
                }
              } else {
                // If no existing link node found, create a new one
                const textContent = selection.getTextContent() || sanitizedTitle;
                const parentNode = node.getParent();
                
                if (!parentNode || $isRootOrShadowRoot(parentNode)) {
                  const paragraph = $createParagraphNode();
                  const linkNode = $createCustomLinkNode(sanitizedUrl, {
                    title: sanitizedTitle,
                    linkType: newLinkType || 'external',
                  });
                  
                  const textNode = $createTextNode(textContent);
                  linkNode.append(textNode);
                  paragraph.append(linkNode);
                  selection.insertNodes([paragraph]);
                } else {
                  const linkNode = $createCustomLinkNode(sanitizedUrl, {
                    title: sanitizedTitle,
                    linkType: newLinkType || 'external',
                  });
                  
                  const textNode = $createTextNode(textContent);
                  linkNode.append(textNode);
                  selection.insertNodes([linkNode]);
                }
              }
            }
            return true;
          });
          return true;
        },
        COMMAND_PRIORITY_CRITICAL,
      );
    }, [editor]);
  
    useEffect(() => {
      if (isLinkEditMode && inputRef.current) {
        inputRef.current.focus();
      }
    }, [isLinkEditMode, isLink]);
  
    const monitorInputInteraction = (
      event,
    ) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleLinkSubmission();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        setIsLinkEditMode(false);
      }
    };
  
    const handleLinkSubmission = () => {
      if (lastSelection !== null) {
        if (linkUrl !== '') {
          editor.dispatchCommand(UPDATE_INITIAL_LINK_COMMAND, {
            url: sanitizeUrl(editedLinkUrl),
            title: linkTitle !== '' ? linkTitle : sanitizeUrl(editedLinkUrl),
            newLinkType: value?.type || 'external',
          });
        }
        setEditedLinkUrl('https://');
        setIsLinkEditMode(false);
      }
    };

    useEffect(() => {
      if (externalLinkUrl.length > 0 && externalLinkUrl !== 'https://') {
        setEditedLinkUrl(externalLinkUrl);
        setLinkTitle(externalLinkUrl);
      }
    }, [externalLinkUrl]);
  
    const getLinkIcon = (type) => {
      switch (type) {
        case 'note':
        case 'searchItem_note':
          return (
            <Tooltip title="Note">
              <NoteIcon sx={{ fontSize: 16 }} />
            </Tooltip>
          );
        case 'searchItem_tagBoard':
        case 'tagBoard':
        case 'searchItem_boardGroup':
        case 'tagGroup':
        case 'searchItem_tag':
        case 'tag':
          return (
            <Tooltip variant="plain" title="Tag">
              <TagIcon sx={{ fontSize: 16 }} />
            </Tooltip>
          );
        case 'searchItem_persona':
        case 'persona':
          return (
            <Tooltip variant="plain" title="Persona">
              <PersonaIcon sx={{ fontSize: 16 }} />
            </Tooltip>
          );
        case 'searchItem_journey':
        case 'journey':
          return (
            <Tooltip variant="plain" title="Journey">
              <JourneyIcon sx={{ fontSize: 16 }} />
            </Tooltip>
          );
        case 'searchItem_feature':
        case 'feature':
          return (
            <Tooltip variant="plain" title="Feature">
              <FeatureIcon sx={{ fontSize: 16 }} />
            </Tooltip>
          );
        case 'searchItem_sitemap':
        case 'sitemap':
          return (
            <Tooltip variant="plain" title="Sitemap">
              <SitemapIcon sx={{ fontSize: 16 }} />
            </Tooltip>
          );
        case 'searchItem_page':
        case 'page':
          return (
            <Tooltip variant="plain" title="Page">
              <PageIcon sx={{ fontSize: 16 }} />
            </Tooltip>
          );
        case 'searchItem_pageset':
        case 'pageset':
          return (
            <Tooltip variant="plain" title="Pageset">
              <PagesetIcon sx={{ fontSize: 16 }} />
            </Tooltip>
          );
        default:
          return (
            <Tooltip variant="plain" title="External">
              <ExternalIcon sx={{ fontSize: 16 }} />
            </Tooltip>
          );
      }
    };
  
    return (
      <div ref={editorRef} className="link-editor">
        {!isLink ? null : (linkUrl && linkUrl !== 'https://' && !isLinkEditMode) ? (
          // Simplified view for existing links
          <Box sx={{ p: 1.5, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography level="body-sm" sx={{ 
                display: 'inline-flex', 
                alignItems: 'center',
                gap: 1,
                color: 'text.secondary'
              }}>
                {getLinkIcon(linkType)}
                {linkUrl.startsWith('/client/') ? (
                  <Link to={linkUrl}>
                    {linkTitle || linkUrl}
                  </Link>
                ) : (
                  <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                    {linkTitle || linkUrl}
                  </a>
                )}
              </Typography>
            </Box>
            <Button 
              size="sm" 
              variant="outlined"
              onClick={() => setIsLinkEditMode(true)}
            >
              Edit
            </Button>
          </Box>
        ) : isLinkEditMode ? (
          // Existing tab layout for editing
          <Box sx={{ flexGrow: 1, p: .75}}>
            <Tabs aria-label="tabs" defaultValue={0} sx={{ bgcolor: 'transparent' }}>
              <TabList
                disableUnderline
                sx={{
                  p: 0.5,
                  gap: 0.5,
                  borderRadius: 'xl',
                  bgcolor: 'background.level1',
                  [`& .${tabClasses.root}[aria-selected="true"]`]: {
                    boxShadow: 'sm',
                    bgcolor: 'background.surface',
                  },
                }}
              >
                <Tab sx={{ fontSize: 14 }} disableIndicator xs={{ backgroundColor: 'none' }}>Internal link</Tab>
                <Tab sx={{ fontSize: 14 }} disableIndicator>External link</Tab>
              </TabList>
              <TabPanel value={0} sx={{ px: .75, pb: 1, m: 0 }}>
                <Box sx={{ mb: 1 }}>
                  <Search size="md" linkTitle={linkTitle} setLinkTitle={setLinkTitle} value={value} setValue={setValue} url={editedLinkUrl} setUrl={setEditedLinkUrl} mode="addlink" />
                </Box>
                <Button size="sm" sx={{ mt: .5 }} onClick={handleLinkSubmission}>Save</Button>
                <Button variant='outlined' size="sm" sx={{ ml: 1, mt: .5 }} onClick={() => setIsLinkEditMode(false)}>Cancel</Button>
              </TabPanel>
              <TabPanel value={1} sx={{ px: .75, m: 0 }}>
                <Box sx={{ mb: 1 }}>
                  <Input size="md" value={externalLinkUrl} onChange={(e) => setExternalLinkUrl(e.target.value)} />     
                </Box>
                <Button size="sm" sx={{ mt: .5 }} onClick={handleLinkSubmission}>Save</Button>
                <Button variant='outlined' size="sm" sx={{ ml: 1, mt: .5 }} onClick={() => setIsLinkEditMode(false)}>Cancel</Button>           
              </TabPanel>
            </Tabs>
          </Box>
        ) : null}
      </div>
    );
}