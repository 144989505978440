import { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Input,
  Button,
  ButtonGroup,
  IconButton,
  Stack,
  Textarea
} from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import HighlightIcon from '@mui/icons-material/Highlight';
import TagPicker from '../../../Tags/Editor/TagPicker';
import { useMultiTasking } from '../../../../contexts/multiTaskingContext';

const SpreadsheetRowPane = ({ content }) => {
  console.log('SpreadsheetRowPane content', content);
  const { updatePanel, panels } = useMultiTasking();
  const { row, headers, handleHeaderUpdate, handleValueUpdate } = content || {};
  
  const [editingHeader, setEditingHeader] = useState(false);
  const [editingValue, setEditingValue] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [pendingTag, setPendingTag] = useState([]);
  
  const displayHeader = headers?.find(h => h.id === row?.displayHeaderId);
  const displayValue = row?.cells?.find(cell => cell.headerId === row?.displayHeaderId)?.value;
  
  const [draftHeader, setDraftHeader] = useState('');
  const [draftValue, setDraftValue] = useState('');
  const [editingCellId, setEditingCellId] = useState(null);
  const [draftCellValue, setDraftCellValue] = useState('');
  const [editingHeaderId, setEditingHeaderId] = useState(null);
  const [draftHeaderLabel, setDraftHeaderLabel] = useState('');

  useEffect(() => {
    if (editingHeader) {
      setDraftHeader(displayHeader?.label || '');
    }
    if (editingValue) {
      setDraftValue(displayValue || '');
    }
    if (!editingCellId) {
      setDraftCellValue('');
    }
  }, [editingHeader, editingValue, editingCellId, displayHeader?.label, displayValue]);

  // Find the current panel
  const currentPanel = panels.find(panel => 
    panel.type === 'spreadsheet_row' && panel.content?.row?.id === row?.id
  );

  const handleHeaderSave = () => {
    if (!row?.displayHeaderId || !currentPanel) return;
    handleHeaderUpdate(row.displayHeaderId, draftHeader);
    
    const updatedHeaders = headers.map(header =>
      header.id === row.displayHeaderId ? { ...header, label: draftHeader } : header
    );
    
    updatePanel(currentPanel.panelId, {
      type: 'spreadsheet_row',
      title: displayValue || `Row ${row.rowIndex + 1}`,
      content: {
        row: { ...row },
        headers: updatedHeaders,
        handleHeaderUpdate,
        handleValueUpdate
      }
    });
    
    setEditingHeader(false);
  };

  const handleValueSave = () => {
    if (!row?.id || !row?.displayHeaderId || !currentPanel) return;
    handleValueUpdate(row.id, row.displayHeaderId, draftValue);
    
    // Update the panel content with new value
    const updatedRow = {
      ...row,
      cells: row.cells.map(cell =>
        cell.headerId === row.displayHeaderId ? { ...cell, value: draftValue } : cell
      )
    };
    
    updatePanel(currentPanel.panelId, {
      type: 'spreadsheet_row',
      title: draftValue || `Row ${row.rowIndex + 1}`,
      content: {
        row: updatedRow,
        headers,
        handleHeaderUpdate,
        handleValueUpdate
      }
    });
    
    setEditingValue(false);
  };

  const handleUpdateTags = async (tags = pendingTag) => {
    setSelectedTags(tags);
    // Add logic to save tags to the highlighted content
  };

  const handleHighlight = () => {
    // Add highlighting functionality similar to notes
    // This would involve selecting text and applying tags
  };

  const handleCellSave = (cellId, headerId) => {
    if (!row?.id || !currentPanel) return;
    handleValueUpdate(row.id, headerId, draftCellValue);
    
    // Update the panel content with new value
    const updatedRow = {
      ...row,
      cells: row.cells.map(cell =>
        cell.headerId === headerId ? { ...cell, value: draftCellValue } : cell
      )
    };
    
    updatePanel(currentPanel.panelId, {
      type: 'spreadsheet_row',
      title: row.displayHeaderId === headerId ? draftCellValue : displayValue || `Row ${row.rowIndex + 1}`,
      content: {
        row: updatedRow,
        headers,
        handleHeaderUpdate,
        handleValueUpdate
      }
    });
    
    setEditingCellId(null);
  };

  const handleHeaderLabelSave = (headerId) => {
    if (!currentPanel) return;
    handleHeaderUpdate(headerId, draftHeaderLabel);
    
    const updatedHeaders = headers.map(header =>
      header.id === headerId ? { ...header, label: draftHeaderLabel } : header
    );
    
    updatePanel(currentPanel.panelId, {
      type: 'spreadsheet_row',
      title: displayValue || `Row ${row.rowIndex + 1}`,
      content: {
        row: { ...row },
        headers: updatedHeaders,
        handleHeaderUpdate,
        handleValueUpdate
      }
    });
    
    setEditingHeaderId(null);
  };

  if (!row || !headers || !handleHeaderUpdate || !handleValueUpdate) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography level="body-sm">Loading spreadsheet data...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={2}>

            {/* All Cells Section */}
            <Stack spacing={2}>
              {headers.map(header => {
                const cell = row.cells.find(c => c.headerId === header.id);
                const isEditingCell = editingCellId === cell?.headerId;
                const isEditingHeader = editingHeaderId === header.id;
                
                return (
                  <Card 
                    key={header.id} 
                    variant="outlined"
                    sx={{
                      '& .edit-button': {
                        opacity: 0,
                        transition: 'opacity 0.2s'
                      },
                      '&:hover .edit-button': {
                        opacity: 1
                      }
                    }}
                  >
                    <CardContent>
                      <Stack spacing={1}>
                        {/* Cell Header */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          {isEditingHeader ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 1 }}>
                              <Input
                                autoFocus
                                size="sm"
                                value={draftHeaderLabel}
                                onChange={(e) => setDraftHeaderLabel(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') handleHeaderLabelSave(header.id);
                                  if (e.key === 'Escape') setEditingHeaderId(null);
                                }}
                              />
                              <ButtonGroup spacing={1}>
                                <Button 
                                  size="sm" 
                                  variant="outlined" 
                                  onClick={() => setEditingHeaderId(null)}
                                >
                                  Cancel
                                </Button>
                                <Button 
                                  size="sm" 
                                  variant="solid" 
                                  onClick={() => handleHeaderLabelSave(header.id)}
                                >
                                  Save
                                </Button>
                              </ButtonGroup>
                            </Box>
                          ) : (
                            <Typography 
                              level="title-md"
                              endDecorator={
                                <IconButton 
                                  className="edit-button"
                                  size="sm" 
                                  onClick={() => {
                                    setEditingHeaderId(header.id);
                                    setDraftHeaderLabel(header.label || '');
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              }
                            >
                              {header.label}
                            </Typography>
                          )}
                          {/* <IconButton size="sm" onClick={handleHighlight}>
                            <HighlightIcon />
                          </IconButton> */}
                        </Box>

                        {/* Cell Value */}
                        {isEditingCell ? (
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Textarea
                              autoFocus
                              minRows={2}
                              value={draftCellValue}
                              onChange={(e) => setDraftCellValue(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                  e.preventDefault();
                                  handleCellSave(cell.id, header.id);
                                }
                                if (e.key === 'Escape') setEditingCellId(null);
                              }}
                            />
                            <ButtonGroup spacing={1}>
                              <Button 
                                size="sm" 
                                variant="outlined" 
                                onClick={() => setEditingCellId(null)}
                              >
                                Cancel
                              </Button>
                              <Button 
                                size="sm" 
                                variant="solid" 
                                onClick={() => handleCellSave(cell.id, header.id)}
                              >
                                Save
                              </Button>
                            </ButtonGroup>
                          </Box>
                        ) : (
                          <Typography
                            level="body-md"
                            sx={{ whiteSpace: 'pre-wrap', minHeight: '24px' }}
                            endDecorator={
                              <IconButton 
                                className="edit-button"
                                size="sm" 
                                onClick={() => {
                                  setEditingCellId(cell?.headerId);
                                  setDraftCellValue(cell?.value || '');
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            }
                          >
                            {cell?.value || ''}
                          </Typography>
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                );
              })}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SpreadsheetRowPane;