import { firebaseApp } from '../../configs/firebaseConfig';
import React, { useState, createContext, useContext } from 'react';
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useAiResults } from './aiResultsContext';
import { getFileTypeFromFilePath } from '../utils/utils';

const bucketName = 'gs://noteduxai.appspot.com';

const app = firebaseApp()

// Initialize the Vertex AI service
const vertexAI = getVertexAI(app);

// Initialize the generative model with a model that supports your use case
// Gemini 1.5 models are versatile and can be used with all API capabilities
const modelText = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });
const modelJson = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash", generationConfig: { "responseMimeType": "application/json" } });
const modelPdf = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash-001" });

// Create the context
const AiPaneContext = createContext();

const AI_PROMPTS = {
  "PROJECT_CONTEXT": "Im building a new app that helps user researchers and strategists to generate insights from research.",
  "SUMMARY": "summarise the following text and return the result as markdown syntax: ",
  "PERSONAS": "generate the key personas from understanding the attached document, give them a fictional name and a fictional image, who are the different actors and what goals/values/pain-points do they have? Return the result as a JSON object following this format : results = [{name: '', description: '', image: '', goals: [], painPoints: [], needs: [], values: [], quote: ''}]",
  "SUMMARY_PDF": "summarise the attached document and return the result as markdown syntax",
  "FEATURES": `generate 10 applicaton/website feature ideas from the following text and return the result as a JSON object following this format : results = [{
    title: '',
    description: '',
    category: '',
    priority: '1-4',
    status: '', // just for future reference
    dueDate: '', // just for future reference
    tags: [], // just for future reference
}]`,
  "TODOS": `generate 10 todos from the following text and return the result as a JSON object following this format : results = [{
    text: '', // text from the prompt
    isCompleted: false,
    dueBy: '', // date in firestore date format with a date time of today + 1 working day
    order: 1,
}]`
}

const generatePersonasFromPdfProvider = async (userId, fileName) => {
  console.log('generatePersonasFromPdfProvider file ', fileName);
  try {
    // Build the GCS URI for the temporary file
    const gcsUri = `${bucketName}/users/${userId}/uploads/${fileName}`;

    const filePart = {
      file_data: {
        file_uri: gcsUri,
        mime_type: 'application/pdf',
      },
    };

    const textPart = {
      text: AI_PROMPTS.PERSONAS,
    };

    const request = {
      contents: [{role: 'user', parts: [filePart, textPart]}],
    };

    console.log('request ', request);

    const resp = await modelPdf.generateContent(request);
    const contentResponse = await resp.response;
    console.log(JSON.stringify(contentResponse));

    const responseText = await contentResponse.text();
    
    // Remove markdown formatting if present
    const cleanedText = responseText.replace(/```json\n|\n```/g, '').trim();
    
    // Parse the cleaned response text as JSON
    const jsonResponse = JSON.parse(cleanedText);
    
    // Ensure the response is an array
    return Array.isArray(jsonResponse) ? jsonResponse : [jsonResponse];

  } catch (error) {
    console.error('Error in generatePersonasFromPdfProvider:', error);
    return []; // Return an empty array in case of error
  }
}

const summariseThisPdfProvider = async (userId, fileName) => {
  // console.log('summariseThisPdfProvider file ', fileName);

  const fileType = getFileTypeFromFilePath(fileName);
  // console.log("fileType within summariseThisPdfProvider", fileType)
  if (fileType !== "pdf") {
    // console.log("File is not a PDF")
    return "File is not a PDF"
  }
  
  try {
    // Build the GCS URI for the temporary file
    const gcsUri = `${bucketName}/users/${userId}/uploads/${fileName}`;

    const filePart = {
      file_data: {
        file_uri: gcsUri,
        mime_type: 'application/pdf',
      },
    };

    const textPart = {
      text: AI_PROMPTS.SUMMARY_PDF,
    };

    const request = {
      contents: [{role: 'user', parts: [filePart, textPart]}],
    };

    console.log('request ', request);

    const resp = await modelPdf.generateContent(request);
    const contentResponse = await resp.response;
    console.log(JSON.stringify(contentResponse));

    return contentResponse.text();
  } catch (error) {
    console.error('Error in summariseThisPdfProvider:', error);
    
    // Provide more specific error messages
    if (error.message.includes('Internal error encountered')) {
      return "An internal error occurred with the AI service. Please try again later.";
    } else if (error.message.includes('fetch-error')) {
      return "There was a problem connecting to the AI service. Please check your internet connection and try again.";
    } else {
      return `An error occurred while summarizing the PDF: ${error.message}`;
    }
  }
}

const generateFeaturesFromPdfProvider = async (userId, fileName) => {
  console.log('generateFeaturesFromPdfProvider file ', fileName);
  try {
    // Build the GCS URI for the temporary file
    const gcsUri = `${bucketName}/users/${userId}/uploads/${fileName}`;

    const filePart = {
      file_data: {
        file_uri: gcsUri,
        mime_type: 'application/pdf',
      },
    };

    const textPart = {
      text: AI_PROMPTS.FEATURES,
    };

    const request = {
      contents: [{role: 'user', parts: [filePart, textPart]}],
    };

    console.log('request ', request);

    const resp = await modelPdf.generateContent(request);
    const contentResponse = await resp.response;
    console.log(JSON.stringify(contentResponse));

    const responseText = await contentResponse.text();
    
    // Attempt to extract JSON from the response
    const jsonMatch = responseText.match(/\[[\s\S]*\]/);
    if (jsonMatch) {
      const cleanedText = jsonMatch[0].trim();
      try {
        const jsonResponse = JSON.parse(cleanedText);
        return Array.isArray(jsonResponse) ? jsonResponse : [jsonResponse];
      } catch (parseError) {
        console.error('Error parsing JSON:', parseError);
      }
    }
    
    // If no valid JSON is found, extract features from the text
    const features = extractFeaturesFromText(responseText);
    return features;

  } catch (error) {
    console.error('Error in generateFeaturesFromPdfProvider:', error);
    return { error: true, message: error.message, details: error.toString() };
  }
}

const extractFeaturesFromText = (text) => {
  const featureRegex = /\*\*(.*?):\*\*(.*?)(?=\n\n|\*\*|$)/gs;
  const matches = [...text.matchAll(featureRegex)];
  
  return matches.map((match, index) => ({
    title: match[1].trim(),
    description: match[2].trim(),
    category: 'Extracted',
    priority: '2',
    status: 'Proposed',
    dueDate: '',
    tags: [],
  }));
}

const summariseThisTextProvider = async (text) => {
  const generatedContent = await modelText.generateContent(AI_PROMPTS.SUMMARY + text);
  const response = await generatedContent.response;
  const txt = await response.text()
  console.log("txt", txt)
  if (txt && txt !== "") {
    return txt
  } else {
    return "No response from model"
  }
}

const featureSuggestThisProvider = async (text) => {
  const generatedContent = await modelJson.generateContent(AI_PROMPTS.FEATURES + text);
  const response = await generatedContent.response;
  console.log("response", response)
  const txt = await response.text()
  console.log("txt", txt)
  const results = await JSON.parse(txt);
  console.log("results", results)
  if (results && results.length > 0) {
    return results
  } else {
    return "No response from model"
  }
}

const suggestTodosProvider = async (text) => {
  const generatedContent = await modelJson.generateContent(AI_PROMPTS.TODOS + text);
  const response = await generatedContent.response;
  console.log("response", response)
  const txt = await response.text()
  console.log("txt", txt)
  const results = await JSON.parse(txt);
  console.log("results", results)
  if (results && results.length > 0) {
    return results
  } else {
    return "No response from model"
  }
}

// Create a provider component
export function AiPaneProvider({ children }) {
  const { setAiResults, loading, setLoading } = useAiResults();
  const [aiPaneVisible, setAiPaneVisible] = useState(false);
  const [editorContent, setEditorContent] = useState(false);
  const [insertText, setInsertText] = useState(false);
  const [type, setType] = useState("");

  const handleClosePane = () => {
    setAiPaneVisible(false)
  }

  const generatePersonas = async (userId, fileName, docId) => {
    setType("PERSONAS")
    setAiPaneVisible(true)
    setLoading(true)
    const response = await generatePersonasFromPdfProvider(userId, fileName)
    console.log("response of generatePersonas", response)
    setLoading(false)
    setAiResults((prev) => ({ ...prev, persona: { response, id: docId } }))
  }

  const generateFeaturesFromPdf = async (userId, fileName, docId) => {
    setType("FEATURES")
    setAiPaneVisible(true)
    setLoading(true)
    const response = await generateFeaturesFromPdfProvider(userId, fileName)
    setLoading(false)
    setAiResults((prev) => ({ ...prev, features: { response, id: docId } }))
  }

  const featureSuggestThis = async (text, docId) => {
    setType("FEATURES")
    setAiPaneVisible(true)
    setLoading(true)
    const response = await featureSuggestThisProvider(text)
    setLoading(false)
    setAiResults((prev) => ({ ...prev, features: { response, id: docId } }))
  }

  const summariseThisText = async (text, docId) => {
    setType("SUMMARY")
    setAiPaneVisible(true)
    setLoading(true)
    const response = await summariseThisTextProvider(text)
    setLoading(false)
    setAiResults((prev) => ({ ...prev, summary: { response, id: docId } }))
  }

  const summariseThisPdf = async (userId, fileName, docId) => {
    setType("SUMMARY_PDF")
    setAiPaneVisible(true)
    setLoading(true)
    const response = await summariseThisPdfProvider(userId, fileName)
    setLoading(false)
    setAiResults((prev) => ({ ...prev, summary: { response, id: docId } }))
  }

  const suggestTodos = async (text) => {
    setType("TODOS")
    setAiPaneVisible(true)
    setLoading(true)
    const response = await suggestTodosProvider(text)
    setLoading(false)
    setAiResults(response)
  }

  const value = {
    summariseThisText,
    summariseThisPdf,
    featureSuggestThis,
    loading,
    setLoading,
    aiPaneVisible,
    setAiPaneVisible,
    handleClosePane,
    editorContent,
    setEditorContent,
    insertText,
    setInsertText,
    suggestTodos,
    generatePersonas,
    generateFeaturesFromPdf,
    type
  };

  return <AiPaneContext.Provider value={value}>{children}</AiPaneContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useAiPane() {
  return useContext(AiPaneContext);
}
