import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Input, Card, CardContent, Grid, IconButton, Textarea, Tooltip, Typography, ButtonGroup, Modal, ModalClose, ModalDialog, DialogTitle, DialogContent } from "@mui/joy";
import { Check, Delete, RadioButtonUnchecked } from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import { TimeAgo } from '../../../utils/utils';
import { parseISO, isPast } from 'date-fns';
import TagPicker from '../../Tags/Editor/TagPicker';
import { useTodos } from '../../../contexts/todoContext';
import { useTags } from '../../../contexts/tagsContext';
import { debounce } from 'lodash';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InlineLoader from './../../Loaders/InlineLoader/index';

function ToDoCard({ 
  mode, 
  todo, 
  handleUpdateToDo, 
  pendingValues, 
  setPendingValues, 
  handleDeleteToDo, 
  listeners, 
  attributes, 
  handleDragEnd, 
  handleMoveToTop 
}) {
  const { tags } = useTags();
  const { fetchToDos } = useTodos();

  const [edit, setEdit] = useState(true);
  const [calendarModal, setCalendarModal] = useState(false);
  const [triggerCompleted, setTriggerCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  // Tags
  const [selectedTags, setSelectedTags] = React.useState(todo.tags ? todo.tags : []);
  const [pendingTag, setPendingTag] = React.useState([]);

  const handleUpdateTags = async (tags = pendingTag) => {
    setSelectedTags(tags);
    handleUpdateToDo({...todo, tags: tags});
  }

  const handleClickDone = () => {
    setLoading(true);
    setTriggerCompleted(true);
  }

  const handleDeleteToDoClick = (id) => {
    console.log('Deleting ToDo', id);
    handleDeleteToDo(id);
    fetchToDos();
  }

  const handleEditText = () => {
    setPendingValues({...todo, text: todo.text});    
    setEdit(!edit);
  }

  const handleSaveText = () => {
    handleUpdateToDo(pendingValues);
    setEdit(!edit);
  }

  const handleUpdateDueByDate = () => {
    handleUpdateToDo(pendingValues);
    setCalendarModal(false);
  }

  const handleCancel = () => {
    setEdit(!edit);
  }

  const handleNewDateClick = (date) => {
    // strip out input label as this value is stored to db and the input label is parsed downstream of fetch
    const { dueByInput, ...newTodo } = todo;
    // translate date in to firestore timestamp
    date = parseISO(date);
    setPendingValues({...newTodo, dueBy: date});
  }

  const handleShowDueDateCalendar = () => {
    setCalendarModal(!calendarModal);
  }

  const handleChange = useCallback(
    debounce((value) => {
      setPendingValues((prevData) => ({ ...prevData, text: value }));
    }, 300), // Adjust the debounce delay as needed
    []
  );

  const moveToTopClick = () => {
    handleMoveToTop(todo.id);
  }

  useEffect(() => {
    setPendingValues({...todo});
  }, []);

  useEffect(() => {
    // console.log('pendingValues', pendingValues);
  }, [pendingValues]);

  useEffect(() => {
    if (triggerCompleted) {
      const timeout = setTimeout(async () => {
        await handleUpdateToDo({...todo, isCompleted: !todo.isCompleted});
        setTriggerCompleted(false);
        setLoading(false);
      }, 300);
      
      return () => clearTimeout(timeout);
    }
  }, [triggerCompleted]);

  return (
    <Card 
      className={triggerCompleted ? 'completed show-on-hover-parent' : 'show-on-hover-parent'} 
      sx={{
        flexDirection: 'column',
        alignItems: 'initial',
        gap: mode === "panel" ? 0 : 1,
        marginBottom: 1.85,
        pt: { xs: mode === "panel" ? 0 : 2, lg: mode === "panel" ? 1.25 : 4 },
        pb: { xs: mode === "panel" ? 0 : 4, lg: mode === "panel" ? 1.25 : 4 },
        px: mode === "panel" ? 0 : 3,
        backgroundColor: todo.isCompleted
          ? 
            '#f5f5f5' 
          : 
            '#fff',
        borderRight: isPast(new Date(todo.dueBy)) ? "solid 2px red" : "solid 2px green",
        boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
        '&:hover': { boxShadow: 'md', backgroundColor: todo.isCompleted ? '#f5f5f5' : '#fff', borderColor: !todo.isCompleted && 'neutral.outlinedHoverBorder' },
      }}
      variant="soft"
      orientation="vertical"
    >
      <CardContent>
        <Grid container spacing={mode === "panel" ? 0 : 2}>
          {/* TO DO TEXT */}
          <Grid item xs={12} lg={8} >
            <CardContent sx={{ pl: { xs: 2, lg: 2, xl: 2 }, pt: .5, fontSize: 14, lineHeight: 2, pr: mode === "panel" ? 0 : 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'start', gap: 1 }}>
                <Box sx={{ display: { xs: 'none', lg: 'block' }, mt: -1 }}>
                  <TagPicker 
                    handleUpdateTags={handleUpdateTags} 
                    setPendingTag={setPendingTag}
                    pendingTag={pendingTag}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                  />
                </Box>
                { edit 
                ? 
                  !todo.isCompleted ?
                    <Typography 
                      onClick={() => handleEditText() } 
                      sx={{ 
                        fontSize: 14, 
                        wordBreak: 'break-all', 
                        lineHeight: 2, 
                        cursor: 'pointer',
                        '&:hover': { borderRadius: '4px', backgroundColor: '#ece6f2', border: '1px solid #ece6f2' },
                        flex: 1,
                        pl: 1,
                        ml: mode === "panel" ? 0 : 2
                      }}
                    >{ todo.text }</Typography>
                  :
                    <Typography  
                      sx={{ 
                        fontSize: 14, 
                        lineHeight: 2, 
                        wordBreak: 'break-all',
                        flex: 1,
                        ml: mode === "panel" ? 0 : 2
                      }}
                    >{ todo.text }</Typography>
                : 
                  <Textarea 
                    sx={{ 
                      p: 2, 
                      fontSize: 14, 
                      lineHeight: 2,
                      ml: mode === "panel" ? 0 : -0,
                      mt: mode === "panel" ? 0 : -2,
                      flex: 1
                    }} 
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSaveText();
                      }
                    }}
                    onBlur={() => handleSaveText()}
                    defaultValue={todo.text}
                    endDecorator={
                      <Box sx={{ display: 'flex', gap: 0.5, flex: 1, mt: 2 }}>
                        <ButtonGroup>
                          <Button onClick={() => handleCancel()} size="sm" variant="outlined" color="neutral" sx={{ mr: 1, ml: 'auto' }}>
                            Cancel
                          </Button>
                          <Button onClick={() => handleSaveText()} size="sm" variant="solid" color="primary" sx={{ ml: 'auto' }}>
                            Save
                          </Button>
                        </ButtonGroup>
                      </Box>
                    } 
                  />
                }
              </Box>
            </CardContent>
          </Grid>
          
          {/* DONE / DUE */}
          <Grid item xs={6} lg={4}>
            <Box sx={{ mt: 0, textAlign: { lg: 'right', xs: 'left' }, ml: { xs: 1, lg: 0 } }}>
              <Box sx={{ mr: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: { xs: 'flex-start', lg: 'flex-end' }, alignItems: 'center' }}>
                  
                  {/* DELETE / DRAG */}
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <Box sx={{ ml: 'auto' }}>
                      { todo.order > 1 &&
                      <Tooltip className="show-on-hover" variant="outlined" color="neutral" title="Move to top">
                        <IconButton onClick={() => moveToTopClick()}>
                          <ArrowUpwardIcon />
                        </IconButton>
                      </Tooltip>
                      }
                      <Tooltip className="show-on-hover" variant="outlined" color="neutral" title="Delete">
                        <IconButton onClick={() => handleDeleteToDoClick(todo.id)}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      { !todo.isCompleted &&
                        <Tooltip title="Drag to change priority" variant="outlined" color="neutral" className="desktop-element">
                          <IconButton
                            size="md"
                            className="show-on-hover"
                            sx={{ mb: 0.75 }}
                            {...attributes}
                            {...listeners}
                          >
                            <DragIndicatorIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </Box>
                  </Box>

                  {/* DONE CHECK */}
                  { loading ?
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: -.5, mr: 2, ml: 1 }}>
                      <InlineLoader color={"#6B7280"} width={24} height={24} loading={loading} />
                    </Box>
                  :
                    <Tooltip variant="outlined" color="neutral" sx={{ display: 'flex' }} title={todo.isCompleted ? "Mark as to do" : "Mark as done"}>
                      <IconButton onClick={handleClickDone} sx={{ mt: -.5, mr: 2, ml: 1 }}>
                        {todo.isCompleted ? <AddIcon /> : <Check />}
                      </IconButton>
                    </Tooltip>
                  }

                  {/* DUE DATE */}
                  { mode !== "panel" &&
                    !todo.isCompleted &&
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          onClick={handleShowDueDateCalendar}
                          variant="outlined"
                          color={isPast(new Date(todo.dueBy)) ? "danger" : "success"}
                          size="sm"
                          sx={{ marginLeft: 'auto' }}
                        >
                          <Box className="mobile-element">
                            <TimeAgo short={true} timestamp={todo.dueBy} />
                          </Box>
                          <Box className="desktop-element">
                            Due&nbsp;<TimeAgo timestamp={todo.dueBy} />
                          </Box>
                        </Button>
                      </Box>
                  }

                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Modal open={calendarModal} onClose={() => setCalendarModal(false)}>
        <ModalDialog size={"md"}>
          <ModalClose />
          <DialogTitle>Select a due date</DialogTitle>
          <DialogContent>
            <Input 
              sx={{ mt: 2 }} 
              type="date" 
              defaultValue={todo.dueByInput}
              onChange={(e) => handleNewDateClick(e.target.value)} 
            />
            <Box sx={{ mt: 2 }}>
              <Button sx={{ mr: 2 }} onClick={() => setCalendarModal(false)} variant="outlined" color="neutral" size="sm">Cancel</Button>
              <Button onClick={() => handleUpdateDueByDate()} variant="solid" color="primary" size="sm">Save</Button>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </Card>
  )
}

export default ToDoCard;