import { useState } from "react";
import { Stack, Typography, Tooltip, Dropdown, MenuButton, Menu, MenuItem, Chip, Box, IconButton } from "@mui/joy";
import { useNotes } from "../../contexts/notesContext";
import InlineSearch from "./InlineSearch";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CreateLink from "../Modals/CreateLink";
import FileUpload from "../Modals/FileUpload";
import FiltersMenu from "../FiltersMenu";
import TagPicker from "../Tags/Editor/TagPicker";

export default function NoteHeader() {

  const { notes, handleCreateNewNote, currentUserNotesFilteredCount, handleFilterTags, filteredTags, pendingTag, setPendingTag } = useNotes()

  // Modals
  const [ createLinkModal, setCreateLinkModal ] = useState(false)
  const [ uploadFileModal, setUploadFileModal ] = useState(false)

  const handleCreateLink = () => {
    setCreateLinkModal(true)
  }

  const handleNewUpload = () => {
    setUploadFileModal(true)
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        py={2}
        pb={1.5}
      >
        <Typography
          fontSize={{ xs: 'md', md: 'lg' }}
          component="h1"
          fontWeight="lg"
          sx={{ 
            mr: 'auto',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          Research <Chip variant="outlined" color="neutral" size="md" slotProps={{ root: { component: 'span' } }}>{currentUserNotesFilteredCount}</Chip>

          <FiltersMenu />
          {/* TO DO reimplement filter by tag once connections are properly 
          strung together as firestore requires an id of tags to run a filter query against, see notes-db executeFilteredQuery */}
          {/* <Box sx={{ mt: -1, display: 'inline-flex', alignItems: 'center' }}>
            <TagPicker 
              handleUpdateTags={handleFilterTags} 
              selectedTags={filteredTags} 
              pendingTag={pendingTag} 
              setPendingTag={setPendingTag}
              mode="filter"
              tooltipTitle="Filter by tag/s"
            /> 
          </Box> */}
        </Typography>

        <Box>
          <Dropdown>
            <MenuButton endDecorator={<KeyboardArrowDownIcon />} variant="solid" color="primary" size="sm">Create</MenuButton>
            <Menu size="sm">
              <MenuItem onClick={handleCreateNewNote}>Note</MenuItem>
              <MenuItem onClick={handleNewUpload}>Upload</MenuItem>
              <MenuItem onClick={handleCreateLink}>Link</MenuItem>
            </Menu>
          </Dropdown>
          <CreateLink createLinkModal={createLinkModal} setCreateLinkModal={setCreateLinkModal} />
          <FileUpload uploadFileModal={uploadFileModal} setUploadFileModal={setUploadFileModal} />
        </Box>

      </Stack>
      
      <Box sx={{ pb: 1.5 }}>
        <InlineSearch />
      </Box>
    </>
  )
}
