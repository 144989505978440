import React, { forwardRef } from 'react';
import { useState } from "react";
import { Box, Button, Dropdown, IconButton, ListDivider, Menu, MenuButton, MenuItem, Tooltip, Typography } from "@mui/joy";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TuneIcon from '@mui/icons-material/Tune';
import ToggleCheckmark from "../Buttons/ToggleCheckmark";
import { useNotes } from "../../contexts/notesContext";
import { useAuth } from "../../../authContext";
import { retrieveNotesFromFirestore } from "../../database/notes-db";
import { useParams } from "react-router-dom";

const FilterContent = forwardRef(({ children, ...props }, ref) => (
  <Box
    ref={ref}
    {...props}
  >
    {children}
  </Box>
));

FilterContent.displayName = 'FilterContent';

function FiltersMenu() {
  const [open, setOpen] = useState(false);
  const { 
    setSearchFilteredNotes, 
    notes, 
    notesFiltered, 
    uploadsFiltered, 
    linksFiltered, 
    handleFilterNotes, 
    handleFilterUploads, 
    handleFilterLinks, 
    handleFilterByUser, 
    userFilters, 
    setUserFilters,
    setNotesFiltered, 
    setUploadsFiltered, 
    setLinksFiltered, 
    tagsFiltered, 
    handleFilterTags, 
    filteredTags, 
    setFilteredTags, 
    pendingTag, 
    setPendingTag, 
    setFilteredNotes, 
    setCurrentUserNotesFilteredCount,
    setTagsFiltered 
  } = useNotes();
  const { currentUser, accountUsers } = useAuth();
  const { accountId, selectedProjectId } = useParams();

  const handleItemClick = (handler) => (event) => {
    event.stopPropagation();
    handler();
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FilterContent>
        <Dropdown open={open}>
          <Tooltip variant="outlined" color="neutral" title={ open ? "Close filters" : "Open filters" } placement="right" arrow>
            <MenuButton 
              slots={{ root: IconButton }} 
              slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }} 
              sx={{ ml: 1 }}
              onClick={() => setOpen(!open)}
            >
              <TuneIcon />
            </MenuButton>
          </Tooltip>
          {/* Filters */}
          <Menu size="sm" placement="bottom-start">
            { currentUser && currentUser.plan === "team" &&
              <>
              {accountUsers && accountUsers.length > 0 && accountUsers.map((user) => (
                <MenuItem key={user.id} onClick={handleItemClick(() => handleFilterByUser(user.id))}>
                  <ToggleCheckmark checked={!userFilters[user.id]} text={user.displayName || user.email} />
                </MenuItem>
              ))}
              <ListDivider />
              </>
            }
            <MenuItem onClick={handleItemClick(handleFilterNotes)}>
              <ToggleCheckmark checked={notesFiltered} text="Notes" />
            </MenuItem>
            <MenuItem onClick={handleItemClick(handleFilterUploads)}>
              <ToggleCheckmark checked={uploadsFiltered} text="Uploads" />
            </MenuItem>
            <MenuItem onClick={handleItemClick(handleFilterLinks)}>
              <ToggleCheckmark checked={linksFiltered} text="Links" />
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button 
                  size="xs" 
                  color="neutral" 
                  variant="plain" 
                  onClick={async () => { 
                    // Reset all filter states
                    setNotesFiltered(false); 
                    setUploadsFiltered(false); 
                    setLinksFiltered(false); 
                    setUserFilters({}); 
                    setTagsFiltered(false);
                    setFilteredTags([]);
                    setPendingTag([]);
                    
                    try {
                      const { notes } = await retrieveNotesFromFirestore({
                        accountId,
                        selectedProjectId,
                        limit: 20,
                        lastDoc: null
                      });

                      setSearchFilteredNotes(notes);
                      setFilteredNotes(false);
                      setCurrentUserNotesFilteredCount(notes.length);
                    } catch (error) {
                      console.error("Error resetting filters:", error);
                      setSearchFilteredNotes([]);
                      setCurrentUserNotesFilteredCount(0);
                    }
                  }}
                >
                  Reset
                </Button>
                <Button size="xs" color="primary" variant="solid" onClick={handleClickAway}>Done</Button>
              </Box>
            </MenuItem>
          </Menu>
        </Dropdown>
      </FilterContent>
    </ClickAwayListener>
  );
}

export default FiltersMenu;
