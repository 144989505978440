import * as React from 'react';
import Stack from '@mui/joy/Stack';
import NoteMoreOptions from './NoteMoreOptions';
import Box from '@mui/joy/Box';
import Pins from '../Pins';
import TagPicker from '../Tags/Editor/TagPicker';
import OpenButton from '../Editor/plugins/OpenButton';
import MoveNote from '../MoveNote';
import { useNotes } from '../../contexts/notesContext';
import MakePrivate from '../MakePrivate';
import CreatedBy from '../CreatedBy';
import { useAuth } from './../../../authContext';
import { useParams } from 'react-router-dom';
import GenAi from '../GenAi';

export default function NotesPaneHeaderView({content}) {

  const { handleSave, noteEditorOpen, checkNoteEditPermissionsWithoutEditor } = useNotes();
  const { currentUser } = useAuth();
  const [ selectedTags, setSelectedTags ] = React.useState(content.tags ? content.tags : []);
  const [ pendingTag, setPendingTag ] = React.useState([]);
  const [ createdByUser, setCreatedByUser ] = React.useState(null);
  const { accountUsers } = useAuth();
  const { accountId, selectedProjectId } = useParams()

  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = React.useState('')

  const handleUpdateTags = async (tags = pendingTag) => {
    // Ensure we have all required fields on each tag
    const formattedTags = tags.map(tag => ({
        id: tag.id,
        boardId: tag.boardId,
        groupId: tag.groupId,
        accountId: tag.accountId || accountId,
        projectId: tag.projectId || selectedProjectId,
        tagId: tag.tagId || tag.id,
        title: tag.title,
        colour: tag.colour,
        references: tag.references || []
    }));

    setSelectedTags(formattedTags);
    handleSave({ 
        note: content, 
        setNewCurrentNote: true, 
        pendingTag: formattedTags, 
        tagsChanged: true 
    });
}

  React.useEffect(() => {
    setSelectedTags(content.tags ? content.tags : []);
  },[content])

  React.useEffect(() => {
    if (accountUsers && accountUsers.length > 0 && content.createdBy) {
      const user = accountUsers.find(user => user.id === content.createdBy);
      if (user) {
        setCreatedByUser(user);
      } else {
        setCreatedByUser({ displayName: "Deleted User (" + content.createdBy + ")" });
      }
    }
  },[accountUsers, content.createdBy])
  
  return (
    <>
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        p: .25
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', ml: .25 }}>
        <Pins noteToDisplay={content} />
        {/* <DockItem type={content.type} /> */}
        {/* <Tags /> */}
        { checkNoteEditPermissionsWithoutEditor({ content, currentUser }) &&
        <TagPicker
          handleUpdateTags={handleUpdateTags} 
          setPendingTag={setPendingTag}
          pendingTag={pendingTag}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          noteToDisplay={content}
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
          snackbarSeverity={snackbarSeverity}
          setSnackbarSeverity={setSnackbarSeverity}
          snackbarMsg={snackbarMsg}
          setSnackbarMsg={setSnackbarMsg}
        />
        }
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ mt: .5 }}><NoteMoreOptions content={content} /></Box>
        { createdByUser && <CreatedBy user={createdByUser} /> }
        { !noteEditorOpen && content.type !== "link" && <OpenButton noteToDisplay={content} /> }        
      </Box>
    </Stack>
    </>
  );
}