import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Button, Box, Typography, Card } from "@mui/joy";
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Carousel forward
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Carousel back

// Data fetch
import { useAuth } from "../../../authContext"; 
import { fetchAssociationsFromFirestore } from "../../database/associations-db";
import { addConnectionToFirestore, fetchConnectionsFromFirestore, fetchConnectionsFromFirestoreByFromId } from "../../database/connections-db";

// D3Plus Network Chart
import { Network } from "d3plus-react";
import Slider from "react-slick";
import ConnectionCard from "../../Components/ConnectionCard";
import CreateConnectionModal from "../../Components/Modals/CreateConnection";

// TO DO
// 1. start with the basic layout based on the design
// 2. add a add connect modal
// 3. focus on the data structure and tweaking the network chart config: https://d3plus.org/?path=/docs/charts-network--documentation
// 4. add a delete interaction
// 5. work on implementing this revised data structure for "+ Connect" interactions in the tagpicker

const methods = {
  // sizeMax: 120,
  // linkSize: 2,
  // total: true,
  width: 800,
  height: 504,
  zoom: false,
  label: d => d.title,
  // color: '#000',
  shape: "Rect",
  shapeConfig: {
    width: 150,
    height: 66,
    fill: "#fff",
    state: {
      active: {
        fill: "#f00"
      }
    },
    labelConfig: {
      fontMax: 12,
      fontMin: 12,
      padding: 2,
    }
  }
  // shapeConfig: {
  //   Rect: {
  //     width: 150, // fixed width for rectangle nodes
  //     height: 66, // fixed height for rectangle nodes
  //     borderRadius: 5, // add border radius
  //     // fill: "#fff",
  //     labelConfig: {
  //       // fontColor: "#fff", // set font color for rectangle nodes
  //       fontMax: 14, // set max font size for rectangle nodes
  //       fontMin: 14, // set min font size for rectangle nodes
  //       padding: 2, // set padding for rectangle nodes
  //     }
  //   },
  //   shape: "Rect" // set default shape for nodes
  // }
};

async function fetchAndParseData({ accountId, selectedProjectId }) {
  try {
    const { connections } = await fetchConnectionsFromFirestore({ accountId, project: selectedProjectId });

    const nodesMap = new Map();
    const links = [];

    connections.forEach(connection => {
      const { fromType, fromId, fromTitle, toType, toId, toTitle, comment } = connection;

      // Add source node if fromId is defined
      if (fromId && !nodesMap.has(fromId)) {
        nodesMap.set(fromId, { id: fromId, groupBy: fromType, title: fromTitle });
      }

      // Add target node if toId is defined
      if (toId && !nodesMap.has(toId)) {
        nodesMap.set(toId, { id: toId, groupBy: toType, title: toTitle });
      }

      // Add link if both fromId and toId are defined
      if (fromId && toId) {
        links.push({ source: fromId, target: toId, comment, title: `${fromTitle} - ${toTitle}` });
      }
    });

    const nodes = Array.from(nodesMap.values());

    return { nodes, links };
  } catch (error) {
    console.error("Error fetching and parsing data:", error);
  }
}

export default function ConnectionsPage() {
  const [associations, setAssociations] = useState({ nodes: [], links: [] });
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [createConnectionModal, setCreateConnectionModal] = useState(false);
  const [source, setSource] = useState('');
  const [sourceId, setSourceId] = useState('');
  const [sourceTitle, setSourceTitle] = useState('');
  const [sourceType, setSourceType] = useState('');
  const [target, setTarget] = useState('');
  const [targetId, setTargetId] = useState('');
  const [targetTitle, setTargetTitle] = useState('');
  const [targetType, setTargetType] = useState('');
  const [comment, setComment] = useState('');  
  const { accountId, selectedProjectId } = useParams();
  const { currentUser } = useAuth();

  const settings = {
    arrows: selectedConnection && selectedConnection.length > 1 ? true : false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5,
    centerMode: false,
    slidesToScroll: 1,
    nextArrow: <ArrowForwardIcon />,
    prevArrow: <ArrowBackIcon />
  };

  const createConnectionInDb = async (connection) => {
    const result = await addConnectionToFirestore({ 
      accountId, 
      project: selectedProjectId, 
      user: currentUser,
      connection: { 
        fromType: sourceType, 
        fromId: sourceId, 
        fromTitle: sourceTitle, 
        toType: targetType, 
        toId: targetId, 
        toTitle: targetTitle,
        comment: comment 
      }
    });
  };

  const handleCreateConnectionModalOpen = async () => {
    setSource('');
    setSourceId('');
    setSourceTitle('');
    setSourceType('');

    setTarget('');
    setTargetId('');
    setTargetTitle('');
    setTargetType('');
    setComment('');
    setCreateConnectionModal(true);
  };

  const handleCreateConnection = async () => {
    createConnectionInDb()
  }
  
  const handleNodeClick = (node) => {
    setSelectedNode(node);
    // Use the selected node.id and check if associations.links contains a matching id in either the link.source or link.target and return the link.comment
    const connections = associations.links.filter(link => link.source === node.id || link.target === node.id);
    setSelectedConnection(connections);
  }

  useEffect(() => {
    fetchAndParseData({ accountId, selectedProjectId })
      .then((data) => {
        if (data) {
          setAssociations(data);
        }
      });
  }, [accountId, selectedProjectId]);

  useEffect(() => {
    if (source) {
      setSourceId(source.id);
      if (source.type == "searchItem_note") {
        setSourceType("note");
      } else if (source.type == "searchItem_persona") {
        setSourceType("persona");
      } else if (source.type == "searchItem_tag") {
        setSourceType("tag");
      } else if (source.type == "searchItem_journey") {
        setSourceType("journey");
      } else if (source.type == "searchItem_feature") {
        setSourceType("feature");
      } else if (source.type == "searchItem_boardGroup") {
        setSourceType("boardGroup");
      } else if (source.type == "searchItem_page") {
        setSourceType("page");
      } else {
        console.log("Unknown source type:", source.type);
      }
    }
    if (target) {
      setTargetId(target.id);
      if (target.type == "searchItem_note") {
        setTargetType("note");
      } else if (target.type == "searchItem_persona") {
        setTargetType("persona");
      } else if (target.type == "searchItem_tag") {
        setTargetType("tag");
      } else if (target.type == "searchItem_journey") {
        setTargetType("journey");
      } else if (target.type == "searchItem_feature") {
        setTargetType("feature");
      } else if (target.type == "searchItem_boardGroup") {
        setTargetType("boardGroup");
      } else if (target.type == "searchItem_page") {
        setTargetType("page");
      } else {
        console.log("Unknown target type:", target.type);
      }
    }
  }, [source, sourceTitle, sourceType, target, targetTitle, targetType, comment]);

  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        minHeight: '100vh',
        mx: 'auto',
        gridTemplateColumns: {
          xs: '1fr',
          sm: '1fr',
        },
        p: 2,
        backgroundColor: '#f4f1f8',
      }}
    >
      <Card
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
          display: 'flex',
          flexDirection: 'column',
          height: '90vh',
          position: 'relative',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            fontSize={{ xs: 'md', md: 'lg' }}
            component="h1"
            fontWeight="lg"
          >
            Connections
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Network 
              config={{ 
                ...methods, 
                nodes: associations.nodes, 
                links: associations.links,
                on: {
                  // Event listener for node click
                  click: handleNodeClick
                },
              }} 
            />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 255,
            background: '#FBFCFE',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            p: 3
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography
              component="h2"
              fontWeight="md"
            >
              { selectedNode && selectedNode.title }
            </Typography>
            <Box sx={{ ml: 'auto' }}>
              <Button onClick={() => handleCreateConnectionModalOpen() } sx={{ mt: -2 }} variant="outlined" startDecorator={<AddIcon />} size="sm" color="primary">
                Connect
              </Button>
            </Box>
          </Box>
          {/* CONNECTIONS CARDS */}
          <Box sx={{ mt: 0 }}>
            <Box sx={{
              py: 2,
              px: 5,
              width: '100%',
              bottom: 0,
              left: 0,
            }} className="connections-slider">
              <Slider { ...settings }>
                {selectedConnection && selectedConnection.length > 0 && selectedConnection.map((item, index) => (
                  <Box sx={{ pb: .5 }} key={index + `c-item`}>
                    <ConnectionCard data={item} />
                  </Box>
                ))}
              </Slider>
            </Box>
          </Box>
        </Box>
      </Card>
      <CreateConnectionModal 
        source={source} 
        setSource={setSource} 
        sourceTitle={sourceTitle}
        setSourceTitle={setSourceTitle}
        sourceType={sourceType}
        setSourceType={setSourceType}
        target={target} 
        targetTitle={targetTitle}
        setTargetTitle={setTargetTitle}
        targetType={targetType}
        setTargetType={setTargetType}
        setTarget={setTarget}
        comment={comment} 
        setComment={setComment}
        handleCreateConnection={handleCreateConnection} 
        createConnectionModal={createConnectionModal} 
        setCreateConnectionModal={setCreateConnectionModal} 
      />
    </Box>
  );
}