import React from 'react';
import { Box, IconButton, Button } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useMultiTasking } from '../../contexts/multiTaskingContext';
import NotesPane from '../MessagesPane/NotesPane';
import AiPane from '../GenAi/AiPane';
import { Fade } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToDos from '../ToDos';
import PersonaPane from '../Personas/PersonaPane';
import { usePersonas } from '../../contexts/personasContext';
import { useNotes } from '../../contexts/notesContext';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from '../../../redirects';
import SpreadsheetRowPane from '../MessagesPane/Uploads/Spreadsheet/SpreadsheetRowPane';

// Create a theme instance (you can put this outside the component)
const theme = createTheme();

const PanelContent = ({ panel, onClose }) => {

  const { setPersonaEditorOpen } = usePersonas();
  const { handleOpenNoteInEditor } = useNotes();
  const { clearPanels } = useMultiTasking();
  const navigate = useNavigate();
  const { accountId, selectedProjectId } = useParams();

  const handleOpenType = ({ type, content }) => {
    // Handle open type
    console.log("handleOpenType", type, content)
    if (type === 'persona') {
      //  Ensure on the personas page
      navigate(`${BASE_PRIVATEAPP_URL}${accountId}/${selectedProjectId}/personas`);
      setPersonaEditorOpen(true);
      clearPanels();
    }
    else if (type === 'note') {
      navigate(`${BASE_PRIVATEAPP_URL}${accountId}/${selectedProjectId}`);
      handleOpenNoteInEditor(content);
      clearPanels();
    }
    else if (type === 'todos') {
      navigate(`${BASE_PRIVATEAPP_URL}${accountId}/${selectedProjectId}/todos`);
      clearPanels();
    }
  }

  const renderContent = () => {
    switch (panel.type) {
      case 'todos':
        return <ToDos mode="panel" panelId={panel.panelId} />;
      case 'note':
        return <NotesPane showSidePane={false} note={panel.content} />;
      case 'persona':
        return <PersonaPane persona={panel.content} />;
      case 'journey':
        return <div>Journey Content</div>;
      case 'ai-result':
        return (
          <ThemeProvider theme={theme}>
            <Fade in timeout={400}>
              <div>
                <AiPane mode="panel" noteToDisplay={panel.content} />
              </div>
            </Fade>
          </ThemeProvider>
        );
      case 'spreadsheet_row':
        return <SpreadsheetRowPane content={panel.content} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ 
        p: 1, 
        display: 'flex', 
        alignItems: 'center', 
        borderBottom: '1px solid', 
        borderColor: 'divider' 
      }}>
        <Box sx={{ ml: 'auto', color: '#000' }}>{ panel.title }</Box>
        <IconButton size="sm" onClick={() => onClose(panel.panelId)} sx={{ top: 0 }}>
          <CloseIcon style={{ color: '#000' }} />
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        {renderContent()}
      </Box>
    </Box>
  );
};

export function MultiTaskingLayout({ children }) {
  const { panels, removePanel } = useMultiTasking();

  return (
    <PanelGroup 
      direction="horizontal" 
      style={{ height: '92.5vh' }}
    >
      <Panel 
        id="main-content" 
        defaultSize={panels.length ? 60 : 100} 
        minSize={30}
        style={{ overflow: 'auto' }}
      >
        {children}
      </Panel>

      {panels.length > 0 && (
        <>
          <PanelResizeHandle />
          <Panel 
            id="side-panels" 
            defaultSize={40} 
            minSize={20}
            style={{ height: '100%', overflow: 'auto' }}
          >
            <PanelGroup 
              direction="vertical"
              style={{ height: '100%' }}
            >
              {panels.map((panel, index) => (
                <React.Fragment key={panel.panelId}>
                  {index > 0 && <PanelResizeHandle />}
                  <Panel>
                    <PanelContent 
                      panel={panel} 
                      onClose={removePanel}
                    />
                  </Panel>
                </React.Fragment>
              ))}
            </PanelGroup>
          </Panel>
        </>
      )}
    </PanelGroup>
  );
}
