import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import NoteListItem from './NoteItem';
import { useNotes } from '../../contexts/notesContext';
import LinkItem from './LinkItem';
import UploadItem from './UploadItem';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function ListPane({ mode = 'default'}) {

  const { 
    notes, 
    filteredNotes, 
    searchFilteredNotes, 
    handleNewSelectedNote,
    hasMoreNotes,
    fetchMoreNotes,
    isLoading 
  } = useNotes()

  // console.log("searchFilteredNotes", searchFilteredNotes)

  const handleViewLink = (note) => {
    handleNewSelectedNote( note , true)
    // setUpdateLinkModal(true);
  }

  const handleViewUpload = (note) => {
    handleNewSelectedNote( note , true)
  }

  const getFileAttributes = (fileName) => {
    if (!fileName) return 'unknown';
    
    try {
      const urlParts = fileName.split('/');
      const fileNameWithExtension = urlParts[urlParts.length - 1].split('?')[0];
      const parts = fileNameWithExtension.split('.');
      const extension = parts.length > 1 ? parts.pop() : '';
    
      switch (extension) {
        case 'jpg':
        case 'png':
        case 'gif':
          return 'image';
        case 'mp4':
        case 'avi':
        case 'mov':
          return 'video';
        case 'pdf':
          return 'pdf';
        case 'txt':
          return 'text';
        case 'ppt':
        case 'pptx':
          return 'presentation';
        case 'xlsx':
        case 'xls':
        case 'csv':
          return 'spreadsheet';
        default:
          return 'unknown';
      }
    } catch (error) {
      return 'unknown';
    }
  };
  
  const getFileExtension = (fileName) => {
      const parts = fileName.split('.');
      return parts.length > 1 ? parts.pop() : '';
    };

  return (
    <Sheet
      id="list-pane"
      sx={{
        mt: 2,
        pr: 1,
        pl: .5,
        backgroundColor: mode === "default" ? '#F4F1F8' : '#fff',
        height: mode === "default" ? '78vh' : 'inherit',
        overflowY: mode === "default" ? 'auto' : 'unset',
      }}
    >
      <InfiniteScroll
        dataLength={notes?.length}
        next={fetchMoreNotes}
        hasMore={hasMoreNotes}
        loader={<div>Loading...</div>}
        scrollableTarget="list-pane"
      >
        {(filteredNotes ? searchFilteredNotes : notes)?.length === 0 ? (
          <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
            No notes found
          </div>
        ) : (
          (filteredNotes ? searchFilteredNotes : notes)?.map((note) => (
            note.type === 'note' ? (
              <NoteListItem
                key={note?.id}
                mode={mode}
                note={note}
              />
            ) : note.type === 'link' ? (
              <LinkItem 
                key={note?.id}
                note={note} 
                mode={mode} 
                handleViewLink={handleViewLink} 
                noteTitle={note?.noteTitle} 
                defaultDescription={note?.description} 
                defaultUrl={note?.url} 
              />
            ) : note.type === 'upload' ? (
              <UploadItem 
                key={note?.id}
                note={note}
                mode={mode} 
                handleViewUpload={handleViewUpload} 
                noteTitle={note?.noteTitle}
                fileExtension={note?.url ? getFileAttributes(note?.url) : 'unknown'}  
                {...note}              
              />
            ) : null
          ))
        )}
      </InfiniteScroll>
    </Sheet>
  );
}
