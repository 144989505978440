import * as React from 'react';
import Box from '@mui/joy/Box';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Link from '@mui/joy/Link';
import { toggleMessagesPane } from '../../utils/utils';
import { TimeAgo } from '../../utils/utils';
import TagsListings from '../Tags/Listings';
import { useNotes } from '../../contexts/notesContext';
import { useMultiTasking } from '../../contexts/multiTaskingContext';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import LockIcon from '@mui/icons-material/Lock';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
export default function NoteListItem({
  note,
  mode = "default",
  // selectedChatId,
  // setSelectedChat,
}) {
  const { selectedNote, handleNewSelectedNote, binPage, setNewSelection, loadNoteContent } = useNotes();
  const { addPanel } = useMultiTasking();
  const selected = selectedNote.id === note.id;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAddPanel = () => {
    addPanel({
      id: note.id,
      type: 'note',
      title: note.noteTitle,
      content: note
    });
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    // Prevent event from bubbling up
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleNoteClick = async () => {
    // First load the full note content
    const fullNote = await loadNoteContent(note.id);
    
    // Then set it as selected note
    handleNewSelectedNote({ ...note, ...fullNote }, true);
    toggleMessagesPane();
  };

  return (
    <React.Fragment>
      <Card 
        onContextMenu={handleContextMenu}
        className={ selected ? 'selected note-item' : 'note-item' }
        sx={{
          flexDirection: 'column',
          alignItems: 'initial',
          gap: 1,
          marginBottom: 1.85,
          paddingTop: .5,
          paddingBottom: 1,
          paddingLeft: 1.5,
          paddingRight: 1.5,
          backgroundColor: '#fbf8ff',
          boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
          '&:hover': { boxShadow: 'md', backgroundColor: '#fff', borderColor: 'neutral.outlinedHoverBorder' },
        }}
        variant="soft"
        orientation="vertical"
      >
        <CardContent>
          <Stack direction="column" spacing={1}>
            <Box sx={{ flex: 1 }}>
            { binPage 
            ?
              <Link
                overlay
                underline="none"
                onClick={handleNoteClick}
                sx={{ color: 'text.tertiary' }}
              >
                  <Typography 
                    endDecorator={note.pinned ? <BookmarkAddedIcon style={{ color: '#636b74', fontSize: '1rem' }} /> : null} 
                    startDecorator={note.private ? <LockIcon style={{ color: '#636b74', fontSize: '1rem' }} /> : null} 
                    level="title-sm"
                  >{note.noteTitle}</Typography>
                  {/* INSERT TAG */}
                  { note.tags && note.tags.length > 0 && (
                    note.tags.map(tag => (
                      <TagsListings key={tag.id} tag={tag} />
                    ))
                  )}
              </Link>
            :
              <Link
                overlay
                underline="none"
                onClick={async () => {
                  // First load the full note content
                  const fullNote = await loadNoteContent(note.id);
                  
                  if (mode === 'nav') {
                    setNewSelection(true)
                  }
                  // Then set it as selected note with the additional content
                  handleNewSelectedNote({ ...note, ...fullNote }, false)
                }}
                sx={{ color: 'text.tertiary' }}
              >
                <Typography 
                  endDecorator={note.pinned ? <BookmarkAddedIcon style={{ color: '#636b74', fontSize: '1rem' }} /> : null} 
                  startDecorator={note.private ? <LockIcon style={{ color: '#636b74', fontSize: '1rem' }} /> : null} 
                  level="title-sm"
                >{note.noteTitle}</Typography>
                {/* INSERT TAG */}
                { note.tags && note.tags.length > 0 && (
                    note.tags.map(tag => (
                      <TagsListings key={tag.id} tag={tag} />
                    ))
                )}
              </Link>
            }
            </Box>
            <Box
              sx={{
                lineHeight: 1.5,
                textAlign: 'right',
              }}
            >
              {/* {messages[0].unread && ( */}
                {/* <CircleIcon sx={{ fontSize: 12 }} color="primary" /> */}
              {/* )} */}
              <Typography
                level="body-xs"
                display={{ xs: 'none', md: 'block' }}
                noWrap
              >
                { note.updated ? 
                  <div>Updated <TimeAgo timestamp={note.updated} /></div> :
                  <div>Created <TimeAgo timestamp={note.timestamp} /></div>
                }
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
      
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          backdrop: {
            invisible: true,
          },
        }}
        disablePortal={false}
        size='sm'
      >
        <MenuItem onClick={() => { handleAddPanel(); handleClose(); }}>
          Open in New Panel
        </MenuItem>
        {/* TO DO: implement share note and bin note*/}
        {/* <MenuItem onClick={handleClose}>Share Note</MenuItem>
        {!binPage && (
          <MenuItem onClick={handleClose} sx={{ color: 'danger.500' }}>
            Move to Bin
          </MenuItem>
        )} */}
      </Menu>
    </React.Fragment>
  );
}
