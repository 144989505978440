import { useState } from 'react';
import { Typography, FormControl, RadioGroup, Radio, Box, Sheet, Button, ButtonGroup } from '@mui/joy';

function SelectTemplateMenu({ open, setOpen, type, options, onSelectHandler, selectedValue, setSelectedValue, selectedData, setSelectedData }) {

    return (
      <>
      <Typography variant="h2" color="text.secondary" sx={{ fontSize: { xs: '1.6rem', md: '2.25rem' }, mt: 1 }}>Select a template</Typography>
      <form
          onSubmit={async (event) => {
              event.preventDefault();
              // pass the data property from options based on the selectedValue matching id
              if (type === "persona") {
                  onSelectHandler({ selectedData, type: "persona" })
              } else if (type === "journey") {
                  onSelectHandler(selectedData.items, selectedData.columns, selectedData.columnsSpan)
              } else if (type === "sitemap") {
                  onSelectHandler(selectedData.items)
              } else if (type === "note") {
                  onSelectHandler({ selectedData, type: "note" })
              } else {
                  onSelectHandler(selectedData)
              }
              
              setOpen(false);
          }}
      >
          <Box sx={{ pb: 3, pt: 3 }}>
              <FormControl sx={{ pt: 0, pl: 0, mb: 3 }}>
                  <RadioGroup size="lg" sx={{ gap: 1.5 }} onChange={(e,v) => {
                      setSelectedValue(e.target.value);
                      const previewData = options.find(option => option.id === e.target.value)
                      setSelectedData(previewData.data)
                  }} defaultValue={options[0].id} value={selectedValue} name="radio-buttons-group-template-selection">
                      {options.map((item, index) => (
                          <Box key={`template_box_${item.id}`}>
                              <Sheet
                                  key={`radio_template_${item.id}`}
                                  sx={{
                                      p: 2,
                                      borderRadius: 'md',
                                      boxShadow: 'sm',
                                  }}
                              >
                                  <Radio 
                                      value={item.id} 
                                      label={item.title} 
                                      overlay
                                      color="primary" 
                                      disableIcon
                                      end="true"
                                      slotProps={{
                                          label: ({ checked }) => ({
                                              sx: {
                                                  fontWeight: 'lg',
                                                  fontSize: 'md',
                                                  color: checked ? 'text.primary' : 'text.secondary',
                                              },
                                          }),
                                          action: ({ checked }) => ({
                                              sx: (theme) => ({
                                                  ...(checked && {
                                                      '--variant-borderWidth': '2px',
                                                      '&&': {
                                                          borderColor: theme.vars.palette.primary[500],
                                                      },
                                                  }),
                                              }),
                                          }),
                                      }}
                                  />
                              </Sheet>
                          </Box>
                      ))}
                  </RadioGroup>
              </FormControl>
              <ButtonGroup orientation="horizontal" sx={{ mt: 2, ml: -.25 }} spacing={2}>
                  <Button size="sm" variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
                  <Button size="sm" variant="solid" type="submit">Use this</Button>
              </ButtonGroup>
          </Box>
      </form>
      </>
    )
}

export default SelectTemplateMenu;