import React, { useEffect, useState } from "react";
import { Box, Stack, Card, Typography } from "@mui/joy";
import { getFileAttributes } from "../../../../utils/utils";
import NotesPaneHeaderView from "../../NotesPaneHeaderView";
import Uploads from "../../Uploads/Images";
import Pdf from "../../Uploads/Pdf";
import Text from "../../Uploads/Text";
import Spreadsheet from "../../Uploads/Spreadsheet";
import { useNotes } from "../../../../contexts/notesContext";
import BinnedNotesHeader from "../../../MyNotes/BinnedNotesHeader";
import BinnedNotesPaneHeader from "../../BinnedNotesPaneHeader";
import VideoTranscripts from "../../../VideoTranscripts";

function UploadLayout({ showSidePane, content }) {
  const { binPage } = useNotes();
  const videoRef = React.useRef(null);
  const containerRef = React.useRef(null);
  const [layoutSpan, setLayoutSpan] = useState("row");

  const getLayoutSpan = (containerWidth) => {
    if (containerWidth < 600) return "column";        // xs
    if (containerWidth < 900) return "row";         // sm
    if (containerWidth < 1200) return "row";        // md
    return "row";                                   // lg and xl
  };

  useEffect(() => {
    const updateColumnSpan = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setLayoutSpan(getLayoutSpan(width));
      }
    };

    const resizeObserver = new ResizeObserver(updateColumnSpan);
    
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      updateColumnSpan(); // Initial calculation
    }

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Box
      sx={{
        flex: 1,
        flexDirection: 'column',
        borderRadius: {
          xs: 0,
        },
        boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
        my: {
          xs: 0,
        },
        p: .2,
        maxWidth: {
          xs: '100vw'
        },
        minHeight: '88.5dvh', // very sensitive value to the height of the window causing a scroll
        maxHeight: '88.5dvh',
        overflow: 'auto',
        color: '#fff',
        backgroundColor: '#fff',
      }}
      ref={containerRef}
    >
      { binPage ? 
        <>
          <BinnedNotesPaneHeader noteToDisplay={content} />
          <Uploads content={content} />
        </>
      :
      getFileAttributes(content.url) === 'image' ?
        <>
          <Stack direction="row">
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <Box sx={{ flex: 3 }}>
                <Uploads content={content} />
              </Box>
              <Box sx={{ flex: 1 }}>
                <NotesPaneHeaderView content={content} />
              </Box>
            </Stack>
          </Stack>
        </>
      : getFileAttributes(content.url) === 'video' ?
        <>
          <Stack direction={layoutSpan}>
            <Box sx={{ flex: 3, p: 1, pb: 0, display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
              <video 
                key={content.url} 
                ref={videoRef} 
                controls 
                style={{ maxWidth: '100%', height: 'auto' }}
              >
                <source src={content.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
            <Box sx={{ flex: 2 }}>
              <NotesPaneHeaderView content={content} />
              {/* Video Transcripts */}
              <VideoTranscripts videoRef={videoRef} content={content} />
            </Box>
          </Stack>
        </>
      : getFileAttributes(content.url) === 'pdf' ?
        <>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <Box sx={{ flex: 3 }}>
              <Pdf content={content} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <NotesPaneHeaderView content={content} />
            </Box>
          </Stack>
        </>
      : getFileAttributes(content.url) === 'text' ?
        <>
          <NotesPaneHeaderView content={content} />
          <Text contentItem={content} />
        </>
      : getFileAttributes(content.url) === 'presentation' ?
        <>
          <NotesPaneHeaderView content={content} />
          <Pdf content={content} />
        </>
      : getFileAttributes(content.url) === 'spreadsheet' ?
        <>
          <Spreadsheet content={content} />
        </>
      : null
    }
    </Box>
  )
}

export default UploadLayout;