import Markdown from 'react-markdown'
import { Card, CardContent } from '@mui/joy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/joy/IconButton';
import Tooltip from '@mui/joy/Tooltip';
import AiResultCard from '../../../../Layouts/AiResultCard';
import { useState } from 'react';

function SummariseResult({ result }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (result?.summary) {
      navigator.clipboard.writeText(result.summary);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }
  };

  return (
    <AiResultCard>
      <div style={{ position: 'relative' }}>
        <Tooltip size="sm" variant='plain' color='neutral' title={copied ? "Copied" : "Copy text"}>
          <IconButton 
            variant='plain' 
            size='sm' 
            onClick={handleCopy}
            sx={{ 
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        <Markdown>{ result?.summary?.response }</Markdown>
      </div>
    </AiResultCard>
  )
}

export default SummariseResult