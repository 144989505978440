import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Chip from '@mui/joy/Chip';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import LabelIcon from '@mui/icons-material/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import CableIcon from '@mui/icons-material/Cable';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import FolderIcon from '@mui/icons-material/Folder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { BASE_PRIVATEAPP_URL } from './../../../../redirects/index';
import Toggler from '../Toggler';
import TagsNavigation from '../Components/Tags';
import StyleIcon from '@mui/icons-material/Style';
import { useAuth } from '../../../../authContext';  
import { set } from 'date-fns';
import { useBoards } from '../../../contexts/boardsContext';
import TagBoards from '../Components/TagBoards';
import { Add, UploadFile } from '@mui/icons-material';
import { useNotes } from '../../../contexts/notesContext';
import { useLoading } from '../../../contexts/loadingContext';
import { createMissingDefaultTagBoard } from '../../../database/tags-db';
import IconButton from '@mui/joy/IconButton';
import { Icon } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SyncIcon from '@mui/icons-material/Sync';
import BrowseNotes from '../../Modals/BrowseNotes';
import CallMadeIcon from '@mui/icons-material/CallMade';
import AiMagicButton from '../../Buttons/AiMagic';

export default function SideNavigation({closeSidebar}) {

  const navigate = useNavigate();
  const { accountId, selectedProjectId, boardId } = useParams() 
  const { currentUser } = useAuth();
  const { boards, setBoards, handleFetchBoards } = useBoards();
  const { handleCreateNewNote, getAndSetData } = useNotes();
  const { running, setRunning } = useLoading();

  const handleClick = (path) => {
    navigate(path);
  };

  const handleAddNote = () => {
    //console.log("handleAddNote", BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId)
    // Create a new note
    handleCreateNewNote()
    // Navigate to the notes page
    navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId);
  }

  const handleAddBoard = async () => {
    //console.log("handleAddBoard")
    // Create new board, and then 
    const newBoard = await createMissingDefaultTagBoard({ user: currentUser, accountId: accountId, projectId: selectedProjectId })
    //console.log("newBoard created", newBoard);
    // Refetch the boards to update navigation etc
    handleFetchBoards()
    // Redirect to the new board
    navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/tags/b/' + newBoard.id);     
  }

  return (
    <>
      <List
        size="sm"
        sx={{
          gap: 1,
          '--List-nestedInsetStart': '30px',
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
        }}
      >
        {/* <ListItem>
          <ListItemButton>
            <HomeRoundedIcon />
            <ListItemContent>
              <Typography level="title-sm">Notes</Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem> */}

        <ListItem nested>
          <Toggler
            renderToggle={({ open, setOpen }) => (
              <ListItemButton>
                <AssignmentRoundedIcon />
                <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '?refresh=true');} }>
                  <Typography level="title-sm">Research</Typography>
                </ListItemButton>
                <IconButton aria-label="Add" size="sm" variant="plain" color="neutral">
                  <KeyboardArrowDownIcon
                    onClick={() => { setOpen(!open); }}
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </IconButton>
              </ListItemButton>
            )}
          >
            <List sx={{ gap: 0.5 }}>
              <ListItem sx={{ mt: 0.5 }}>
                <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '?refresh=true');} }>All</ListItemButton>
              </ListItem>
              <ListItem sx={{ mt: 0.5 }}>
                <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '?refresh=true');} }>Published</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>Highlights</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/bin');} }><DeleteIcon />Bin</ListItemButton>
              </ListItem>
            </List>
          </Toggler>
        </ListItem>

        <ListItem nested>
          <Toggler renderToggle={({ open, setOpen }) => (
            <ListItemButton onClick={() => { setOpen(!open); } }>
              <AssessmentIcon />
              <ListItemContent>
                <Typography level="title-sm">Analyse</Typography>
              </ListItemContent>
              <IconButton aria-label="Add" size="sm" variant="plain" color="neutral">
                  <KeyboardArrowDownIcon
                    onClick={() => { setOpen(!open); }}
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </IconButton>
            </ListItemButton>
          )}>
            <List>
              { boards && boards.length === 1 ?
                <>
                <ListItem>
                  <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/tags/b/' + boards[0].id ) ;} }>
                    <StyleIcon />
                    <ListItemContent sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography level="title-sm">{boards[0] && boards[0].title && boards[0].title}</Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={handleAddBoard}>
                    <Add />
                    <ListItemContent sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography level="title-sm">Add Board</Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
                </>
              : boards.length > 1 
                &&
                <>
                <ListItem nested>
                  <Toggler
                    renderToggle={({ open, setOpen }) => (
                      <ListItemButton onClick={() => { setOpen(!open); }}>
                        <LabelIcon />
                        <ListItemContent>
                          <Typography level="title-sm">Tag Boards</Typography>
                        </ListItemContent>
                        <KeyboardArrowDownIcon
                          sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                        />
                      </ListItemButton>
                    )}
                  >
                    <TagBoards handleRedirect={handleClick} handleClick={handleClick} boards={boards} />
                    <List
                      aria-labelledby="nav-list-tags"
                      size="sm"
                      sx={{
                        '--ListItemDecorator-size': '32px',
                      }}
                    >
                      <ListItem>
                        <ListItemButton onClick={handleAddBoard}>
                          <Add />
                          <ListItemContent sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography level="title-sm">Add Board</Typography>
                          </ListItemContent>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Toggler>
                </ListItem>
                </>
              }
                <ListItem>
                  <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/connections') }}><CableIcon />Connections</ListItemButton>
                </ListItem>
            </List>
          </Toggler>
        </ListItem>

        {/* <ListItem>
          <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/files');} }>
            <FolderIcon />
            <ListItemContent>
              <Typography level="title-sm">Files</Typography>
            </ListItemContent>
            <IconButton aria-label="Add" size="sm" variant="plain" color="neutral" onClick={handleAddNote}>
              <UploadFileIcon />
            </IconButton>
          </ListItemButton>
        </ListItem> */}

        <ListItem nested>
          <Toggler
            renderToggle={({ open, setOpen }) => (
              <ListItemButton onClick={() => { setOpen(!open); } }>
                <MenuBookIcon />
                <ListItemContent>
                  <Typography level="title-sm">Define</Typography>
                </ListItemContent>
                <IconButton aria-label="Add" size="sm" variant="plain" color="neutral">
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </IconButton>
              </ListItemButton>
            )}
          >
            <List sx={{ gap: 0.5 }}>
              <ListItem sx={{ mt: 0.5 }}>
                <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/personas');} }>Personas</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/journeys');} }>Journeys</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/features');} }>Features</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/ia');} }>Navigations</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/pages');} }>Pages &amp; Content</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton disabled>User Flows (coming soon)</ListItemButton> 
                {/* /*  onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/flows');} */ }
              </ListItem>
            </List>
          </Toggler>
        </ListItem>

        
        <ListItem>
          <ListItemButton onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/chats');} }>
            <AiMagicButton />
            <ListItemContent sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography level="title-sm" sx={{ fontWeight: 'bold', color: 'rgba(135,14,184,1)' }}>AI Magic</Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <CallMadeIcon />
            <ListItemContent sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography level="title-sm">Public Site</Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>

      </List>
    </>
  )
}