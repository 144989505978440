import { Button, Dropdown, Menu, MenuButton, MenuItem, FormLabel, Typography } from '@mui/joy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const DisplayHeaderSelector = ({ headers, currentDisplayHeaderId, onHeaderChange }) => {
  const currentHeader = headers?.find(h => h.id === currentDisplayHeaderId);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormLabel sx={{ mr: 1 }}>Header:</FormLabel>
      <Dropdown>
        <MenuButton
        slots={{ root: Button }}
        slotProps={{
          root: {
            variant: "outlined",
            color: "neutral",
            size: "sm"
          }
        }}
        endDecorator={<KeyboardArrowDownIcon />}
      >
        <Typography level="body-sm" sx={{ mr: 1 }}>
          {currentHeader?.label || 'None'}
        </Typography>
      </MenuButton>
      <Menu>
        {headers?.map((header) => (
          <MenuItem 
            key={header.id}
            selected={header.id === currentDisplayHeaderId}
            onClick={() => onHeaderChange(header.id)}
          >
            {header.label}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
    </div>
  );
};

export default DisplayHeaderSelector;