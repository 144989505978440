import { Card, CardContent } from "@mui/joy";

function AiResultCard({ children }) {
  return (
    <Card sx={{ p: 1, border: '1px solid', borderColor: 'divider', backgroundColor: '#fff', mb: 1 }}>
      <CardContent sx={{ position: 'relative' }}>
        { children }
      </CardContent>
    </Card>
  )
}

export default AiResultCard