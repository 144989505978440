import { v4 } from 'uuid';

export const convertToPersonaFields = (inputData) => {
  return {
    name: inputData.name,
    type: "persona",
    data: [
      {
        id: v4(),
        type: 'bigtext',
        field_title: 'Role',
        field_value: 'UX Researcher & Strategist',
        block_location: 'tr_1_1',
        cantDelete: true
      },
      {
        id: v4(),
        type: 'text',
        field_title: 'Name',
        field_value: inputData.name,
        block_location: 'tr_2_1',
        cantDelete: true
      },
      {
        id: v4(),
        type: 'textarea',
        field_title: 'Bio',
        field_value: inputData.description,
        block_location: 'tr_4_1'
      },
      {
        id: v4(),
        type: 'image',
        field_title: 'Image',
        field_value: '',
        block_location: 'tl_1_1',
        cantDelete: true
      },
      {
        id: v4(),
        type: 'quote',
        field_title: 'Quote',
        field_value: inputData.quote,
        block_location: 'tl_2_1'
      },
      {
        id: v4(),
        type: 'list',
        field_title: 'Values',
        items: inputData.values,
        block_location: 'tr_5_1'
      },
      {
        id: v4(),
        type: 'list',
        field_title: 'Goals',
        items: inputData.goals,
        block_location: 'tr_6_1'
      },
      {
        id: v4(),
        type: 'list',
        field_title: 'Pain points',
        items: inputData.painPoints,
        block_location: 'tr_6_2'
      }
    ]
  };
};