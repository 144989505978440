import * as React from 'react';
import { Box, Button, Grid, Sheet, Switch, Typography } from '@mui/joy';
import Add from '@mui/icons-material/Add';
import { useContextualPane } from '../../../contexts/contextualPane';
import { styled } from '@mui/joy/styles';
import Templates from '../../Selects/Templates';
import PersonaEditorDefaultLayout from '../../Personas/components/Layouts/Default';
import HeaderComponent from '../../Personas/components/HeaderComponent';
import PersonaEditorLayout from '../../Personas/components/Layouts';
import TriggerContextualMenu from '../../Buttons/TriggerContextualMenu';
import BrowseTypes from '../../DrawerRight/BrowseTypes';
import DrawerRight from '../../DrawerRight';
import { useDrawerRight } from '../../../contexts/drawerRightContext';
import PreviewButton from '../../PreviewButton';
import ModalWithSidePane from '../../Layouts/ModalWithSidePane';
import Sidepane from '../../Sidepane';

// TO DOs
// Save as template
// View / edit switch

export default function PersonaModalContent({ name, setName, editChecked, setEditChecked, id, setViewPane, viewPane, personaFields, setPersonaFields, editMode }) {
  const [ sidePaneOpen, setSidePaneOpen ] = React.useState(true)

  React.useEffect(() => {
    if (editMode === "edit") {
      setEditChecked(true);
    } else if (editMode === "view") {
      setEditChecked(false);
    }
  }, [editMode])

  return (
    <>
      <HeaderComponent 
        sidePaneOpen={sidePaneOpen} 
        setSidePaneOpen={setSidePaneOpen} 
        fields={personaFields} 
        name={name} 
        setName={setName} 
        editChecked={editChecked} 
        setEditChecked={setEditChecked} 
        setPersonaFields={setPersonaFields} 
      />
      
      <ModalWithSidePane 
        sidePaneOpen={sidePaneOpen} 
        setSidePaneOpen={setSidePaneOpen} 
        sideWidth='20%' 
        SideContent={<Sidepane type="persona" />} 
        MainContent={
          <PersonaEditorLayout 
            setPersonaFields={setPersonaFields} 
            personaFields={personaFields} 
            editChecked={editChecked} 
          />
        }
      />

      <Box sx={{ zIndex: 9999, position: 'fixed', bottom: 40, left: 40  }}>
        <PreviewButton editChecked={editChecked} setEditChecked={setEditChecked} />
      </Box>
    </>
  );
}