import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { getSelectedNode } from '../../../../utils/utils';
import FloatingSmartLinkEditor from './FloatingSmartLinkEditor';
import {$findMatchingParent, mergeRegister} from '@lexical/utils';
import {
  $isRangeSelection, 
  $isLineBreakNode,
  $getSelection,
  BaseSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
  KEY_SPACE_COMMAND,
} from 'lexical';
import {
  $createCustomLinkNode,
  $isCustomLinkNode,
} from '../../nodes/CustomLinkNode';
import { $isAtNodeEnd } from '@lexical/selection';

export default function UseFloatingSmartLinkEditorToolbar({
  editor,
  anchorElem,
  isLinkEditMode,
  setIsLinkEditMode,
}) {
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isLink, setIsLink] = useState(false);
  const [linkType, setLinkType] = useState('external');

  useEffect(() => {
    function updateToolbar() {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const focusNode = getSelectedNode(selection);
        const focusLinkNode = $findMatchingParent(focusNode, $isCustomLinkNode);

        if (focusLinkNode) {
          const linkType = focusLinkNode.getLinkType() || 'external';
          setLinkType(linkType);
        }

        if (!focusLinkNode) {
          setIsLink(false);
          setLinkType('external');
          return;
        }

        const badNode = selection.getNodes().find((node) => {
          const linkNode = $findMatchingParent(node, $isCustomLinkNode);
          return (
            !linkNode?.is(focusLinkNode) &&
            !linkNode &&
            !$isLineBreakNode(node)
          );
        });
        if (!badNode) {
          setIsLink(true);
        } else {
          setIsLink(false);
          setLinkType('external');
        }
      }
    }

    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          setActiveEditor(newEditor);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        CLICK_COMMAND,
        (payload) => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const linkNode = $findMatchingParent(node, $isCustomLinkNode);
            if ($isCustomLinkNode(linkNode) && (payload.metaKey || payload.ctrlKey)) {
              window.open(linkNode.getURL(), '_blank');
              return true;
            }
          }
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_SPACE_COMMAND,
        (event) => {
          
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            console.log("key space command is range selection")
            const node = getSelectedNode(selection);
            const linkNode = $findMatchingParent(node, $isCustomLinkNode);
            console.log("key space command is custom link node", $isCustomLinkNode(linkNode))
            console.log("key space command is at node end", $isAtNodeEnd(selection.anchor))
            // If we're at the end of a CustomLinkNode, let the space through
            if ($isCustomLinkNode(linkNode) && $isAtNodeEnd(selection.anchor)) {
              console.log("returning false as we're at the end of a custom link node")
              return false; // Allow default space behavior
            }
            
            // If we're inside a CustomLinkNode but not at the end
            if ($isCustomLinkNode(linkNode)) {
              return true; // Prevent space from breaking the link
            }
          }
          return false; // Allow default space behavior for all other cases
        },
        COMMAND_PRIORITY_LOW
      ),
    );
  }, [editor]);

  return createPortal(
    <FloatingSmartLinkEditor
      editor={activeEditor}
      isLink={isLink}
      anchorElem={anchorElem}
      setIsLink={setIsLink}
      isLinkEditMode={isLinkEditMode}
      setIsLinkEditMode={setIsLinkEditMode}
      linkType={linkType}
      setLinkType={setLinkType}
    />,
    anchorElem,
  );
}