import { useState } from "react";
import { Button, Box, Card, CardActions, Input, Typography, IconButton } from "@mui/joy"
import { useParams, useNavigate } from "react-router-dom";
import FeedbackSnackbar from './../../../../Snackbar/index';
import Slider from "react-slick";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AiResultCard from "../../../../Layouts/AiResultCard";
import { styled } from '@mui/system';
import { usePersonas } from "../../../../../contexts/personasContext";
import { BASE_PRIVATEAPP_URL } from "../../../../../../redirects/index";

const CustomArrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  transform: 'translateY(-50%)',
  zIndex: 1,
  cursor: 'pointer',
  '& svg': {
    fontSize: '2rem',
    color: theme.vars.palette.primary.main,
  },
}));

function PersonasResult({ result }) {
  const { accountId, selectedProjectId } = useParams();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const { handleCreatePersonaFromAi } = usePersonas();
  const navigate = useNavigate();
  const [addedPersonas, setAddedPersonas] = useState([]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    nextArrow: <IconButton color="neutral" variant="plain" sx={{ 
      backgroundImage: 'none', 
      position: 'absolute', 
      top: 25, 
      right: 10,
      zIndex: 9999,
      '&:before': { content: 'none' }
    }} size="sm"><ArrowForwardIcon style={{ fontSize: '1.5rem' }} /></IconButton>,
    prevArrow: <IconButton color="neutral" variant="plain" sx={{ 
      backgroundImage: 'none', 
      position: 'absolute', 
      top: 25, 
      left: 10,
      zIndex: 9999,
      '&:before': { content: 'none' }
    }} size="sm"><ArrowBackIcon style={{ fontSize: '1.5rem' }} /></IconButton>,
  };

  const handleUsePersona = async (persona, index) => {
    await handleCreatePersonaFromAi(persona)
    setAddedPersonas(prev => [...prev, index])
    setShowSnackbar(true)
    setSnackbarSeverity("success")
    setSnackbarMsg(`${persona.name} persona added successfully`)
  }

  return (
    <>
      <Box>
        <Slider {...settings}>
          {result?.persona?.response.map((persona, index) => (
            <div key={index}>
              <AiResultCard>
                <Box sx={{ fontSize: '0.9rem', pt: 5, flexGrow: 1, overflowY: 'auto', mb: 1 }}>
                  <Typography level="h6" sx={{ mb: 1 }}><b>Name:</b> {persona.name}</Typography>
                  <Typography level="h6" sx={{ mb: 1 }}><b>Description:</b> {persona.description}</Typography>
                  <Typography level="h6" sx={{ mb: 1 }}><b>Quote:</b> "{persona.quote}"</Typography>
                  <Typography level="h6" sx={{ mb: 1 }}><b>Goals:</b></Typography>
                  <ul style={{ paddingLeft: '1.5rem' }}>
                    {persona.goals.map((goal, index) => (
                      <li key={index}>{goal}</li>
                    ))}
                  </ul>
                  
                  <Typography level="h6" sx={{ mb: 1 }}><b>Pain Points:</b></Typography>
                  <ul style={{ paddingLeft: '1.5rem' }}>
                    {persona.painPoints.map((painPoint, index) => (
                      <li key={index}>{painPoint}</li>
                    ))}
                  </ul>
                  
                  <Typography level="h6" sx={{ mb: 1 }}><b>Needs:</b></Typography>
                  <ul style={{ paddingLeft: '1.5rem' }}>
                    {persona.needs.map((need, index) => (
                      <li key={index}>{need}</li>
                    ))}
                  </ul>
                  
                  <Typography level="h6" sx={{ mb: 1 }}><b>Values:</b></Typography>
                  <ul style={{ paddingLeft: '1.5rem' }}>
                    {persona.values.map((value, index) => (
                      <li key={index}>{value}</li>
                    ))}
                  </ul>
                  
                </Box>
                <CardActions sx={{ 
                  position: 'sticky',
                  bottom: 30,
                  left: 0,
                  right: 0,
                  zIndex: 2,
                  backgroundColor: 'var(--joy-palette-background-card-surface)',
                  borderTop: '1px solid var(--joy-palette-divider)',
                  py: 1
                }}>
                  {addedPersonas.includes(index) ? (
                    <>
                      <Button 
                        size="sm" 
                        variant="outlined"
                        color="primary"
                        disabled
                      >
                        Persona Added
                      </Button>
                      <Button 
                        size="sm" 
                        variant="outlined" 
                        color="primary"
                        onClick={() => navigate(`${BASE_PRIVATEAPP_URL}${accountId}/${selectedProjectId}/personas/`)}
                      >
                        View Personas
                      </Button>
                    </>
                  ) : (
                    <Button 
                      size="sm" 
                      onClick={() => handleUsePersona(persona, index)} 
                      variant="solid" 
                      color="primary"
                    >
                      Use Persona
                    </Button>
                  )}
                </CardActions>
              </AiResultCard>
            </div>
          ))}
        </Slider>
      </Box>
      <FeedbackSnackbar openSetting={showSnackbar} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} colorSetting={snackbarSeverity} message={snackbarMsg} />
    </>
  )
}

export default PersonasResult
