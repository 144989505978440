import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack
} from '@mui/joy';
import { useMultiTasking } from '../../../../contexts/multiTaskingContext';
import { useContextualPane } from '../../../../contexts/contextualPane';

const SpreadsheetRowCard = ({ row, headers, handleHeaderUpdate, handleValueUpdate }) => {
  const { addPanel, panels, clearPanels } = useMultiTasking();
  const { setActiveItem } = useContextualPane();
  
  const displayHeader = headers?.find?.(h => h?.id === row?.displayHeaderId);
  const displayValue = row?.cells?.find?.(cell => cell?.headerId === row?.displayHeaderId)?.value;

  const handleRowClick = () => {
    if (!row || !headers) return;

    setActiveItem({
      item: row,
      itemType: 'spreadsheet_row',
      mode: 'view',
    });

    const existingPanel = panels.find(panel => panel.type === 'spreadsheet_row');
    if (existingPanel) {
      clearPanels();
    }
    
    console.log('Adding panel with:', {
      row,
      headers,
      handleHeaderUpdate,
      handleValueUpdate
    });

    if (!handleHeaderUpdate || !handleValueUpdate) {
      console.error('Update handlers are missing');
      return;
    }

    addPanel({
      type: 'spreadsheet_row',
      title: displayValue || `Row ${row.rowIndex + 1}`,
      content: row ? {
        row: { ...row },
        headers: [...headers],
        handleHeaderUpdate: handleHeaderUpdate,
        handleValueUpdate: handleValueUpdate
      } : null,
      panelId: row.id
    });
  };

  if (!row || !headers) {
    return null;
  }

  return (
    <Card 
      variant="outlined"
      sx={{
        cursor: 'pointer',
        '&:hover': { bgcolor: 'background.level1' }
      }}
      size="sm"
      onClick={handleRowClick}
    >
      <Box sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography level="title-lg">
              {displayValue || `Row ${row.rowIndex + 1}`}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Card>
  );
};

export default SpreadsheetRowCard;