import React, { useEffect, useState } from 'react';
import { useNotes } from '../../../contexts/notesContext';
import { useNavigations } from '../../../contexts/navigationsContext';
import NoteItem from './NoteItem';
import {ButtonGroup, Button, Card, Box, Typography, Tabs, TabList, Tab, TabPanel } from '@mui/joy';
import InfoIcon from '@mui/icons-material/Info';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Alert from '@mui/joy/Alert';
import IconButton from '@mui/joy/IconButton';
import SwitchViewTypeMenu from './SwitchViewTypeMenu';
import PreviewNote from './PreviewNote';
import NavigationItem from './NavigationItem';
import PreviewNavigation from './PreviewNavigation';
import { tabClasses } from '@mui/joy/Tab';
import { useBrowseTypes } from '../../../contexts/browseTypesContext';
import { useContextualPane } from '../../../contexts/contextualPane';
import Breadcrumb from '../IncitesForTypeInstance/Breadcrumb';
import { classNames } from 'classnames';
import CableIcon from '@mui/icons-material/Cable'; // incite
import AddIncites from './AddIncites';

const typeMapping = {
  searchItem_tagBoard: 'Board',
  searchItem_boardGroup: 'Group',
  searchItem_tag: 'Tag',
  searchItem_note: 'Note',
  searchItem_persona: 'Persona',
  searchItem_feature: 'Feature',
  searchItem_pageset: 'Set',
  searchItem_page: 'Page',
  searchItem_journey: 'Journey',
  searchItem_sitemap: 'Navigation'
  // TO DO add more mappings as needed
};

export default function BrowseTypes({ setViewPane }) {

  const { notes } = useNotes();
  const { sitemaps } = useNavigations();
  const { selected, setSelected, index, setIndex, currentSelectedType, setCurrentSelectedType, linkData } = useBrowseTypes();
  const { listAssociationResults, 
    handleUpdateListAssociationResults, 
    listFilteredAssociationResults,
    handleResetOfFilteredListAssociationResults,
    associationIsFiltered
  } = useContextualPane();
  
  const [ alertOpen, setAlertOpen ] = useState(true)

  useEffect(() => {
    if (currentSelectedType) {
    }
  }, [currentSelectedType])

  // console.log("listFilteredAssociationResults", listFilteredAssociationResults)

  

  return (
    <>
      <Box
        sx={{
          overflowX: 'hidden',
          borderRadius: 8,
        }}
        className="browse-types-container"
      >
        <Tabs
          aria-label="Pipeline"
          value={index}
          onChange={(event, value) => setIndex(value)}
        >
          <TabList
            sx={{
              pt: 1,
              bgcolor: '#fff',
              justifyContent: 'center',
              [`&& .${tabClasses.root}`]: {
                flex: 'initial',
                bgcolor: 'transparent',
                '&:hover': {
                  bgcolor: 'transparent',
                },
                [`&.${tabClasses.selected}`]: {
                  color: 'primary.plainColor',
                  '&::after': {
                    height: 2,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab indicatorInset>
              Browse Inline
            </Tab>
            <Tab indicatorInset>
              Incites <CableIcon />
            </Tab>
          </TabList>
        
          <Box
            sx={(theme) => ({
              bgcolor: '#fff',
            })}
          >
            <TabPanel value={0} sx={{ p: 0, border: 'none' }}>
              <Card sx={{ border: 'none', borderRadius: '0' }}>
                <SwitchViewTypeMenu currentSelectedType={currentSelectedType} setCurrentSelectedType={setCurrentSelectedType} />
                {/* NOTES */}
                { currentSelectedType === "notes" && !selected && notes && notes.length > 0 && notes.map((note, index) => {
                  return (
                    <NoteItem note={note} setSelected={setSelected} />
                  )
                })}
                { currentSelectedType === "notes" && selected &&
                  <Box sx={{ backgroundColor: '#fff', borderRadius: 8, p: 1.8 }}>
                    <PreviewNote selected={selected} setSelected={setSelected} />
                  </Box>
                }
                {/* NAVIGATIONS */}
                { currentSelectedType === "navigations" && sitemaps && sitemaps.length > 0 && sitemaps.map((navigation, index) => {
                  return (
                    <NavigationItem navigation={navigation} setSelected={setSelected} />
                  )
                })}
                { currentSelectedType === "navigations" && selected &&
                  <Box sx={{ height: '100vh', zIndex: 9999, position: 'absolute', top: 0, left: 0, width: '100%', backgroundColor: '#f4f1f8', borderRadius: 8, p: 3 }}>
                    <PreviewNavigation selected={selected} setSelected={setSelected} />
                  </Box>
                }
              </Card>
            </TabPanel>
            <TabPanel value={1}>
              {/* <Alert 
                variant="outlined" 
                startDecorator={<InfoIcon />}
                endDecorator={
                  <IconButton onClick={ () => setAlertOpen(!alertOpen) } variant="soft" color='neutral'>
                    <CloseRoundedIcon />
                  </IconButton>
                }>
                This is the incites panel. This updates when you select an item on the left. If you want to see all the links for this item, click on the Links tab below.
              </Alert> */}
                {/* <Box sx={{ mt: 0 }}>
                  <Breadcrumb setViewPane={setViewPane} activeItem={activeItem} />
                </Box> */}
                <AddIncites />

                <Box sx={{ mt: 5 }}>
                  { listAssociationResults && listAssociationResults.definitions && listAssociationResults.definitions.length > 0 &&
                  <Box flex="1" display="flex" alignItems="center" alignContent="flex-start">
                    <Typography level="body-sm" sx={{ pr: 1, pb: 1.75 }}>Filter</Typography>
                    <ButtonGroup sx={{ mb: 2, border: '1px solid #ccc' }} variant="outlined" aria-label="Filter association results">
                      <Button variant="soft" onClick={handleResetOfFilteredListAssociationResults}>All</Button>
                      {
                        Object.keys(typeMapping).map((property) => {
                          return <Button onClick={() => handleUpdateListAssociationResults(property)} variant="soft" key={property}>{typeMapping[property]}</Button>;
                        })
                      }
                    </ButtonGroup>
                  </Box>
                  }
                  { associationIsFiltered ? 
                    listFilteredAssociationResults && listFilteredAssociationResults.definitions && listFilteredAssociationResults.definitions.length > 0 ? listFilteredAssociationResults.definitions.map((association, index) => {
                      return (
                        <Card sx={{ mb: .5 }}>
                          <Typography level="title-sm">
                            <b>{association.title} ({typeMapping[association.toType]})</b>
                          </Typography>
                          <Typography level="body-sm">
                            {association.comment}
                          </Typography>
                        </Card>
                      )
                    }):
                    <Typography variant="body1">No incites for this type found.</Typography>
                  :
                  listAssociationResults && listAssociationResults.definitions && listAssociationResults.definitions.length > 0 && listAssociationResults.definitions.map((association, index) => {
                    return (
                      <Card sx={{ mb: .5 }}>
                        <Typography level="title-sm">
                          <b>{association.title} ({typeMapping[association.toType]})</b>
                        </Typography>
                        <Typography level="body-sm">
                          {association.comment}
                        </Typography>
                      </Card>
                    )
                  })}
                  { listAssociationResults && listAssociationResults.definitions && listAssociationResults.definitions.length === 0 && <Typography variant="body1">No incites defined, add these using the search bar above.</Typography> }
                </Box>
            </TabPanel>
          </Box>
        </Tabs>
      </Box>
    </>
  )
}