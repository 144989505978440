import database from '../../configs/firebaseConfig'; 
const db = database.firestore(); // Access Firestore database

export async function retrieveAllTags({accountId, projectId}) {
  //console.log("Retrieving all tags for account: ", accountId)
  try {
    const tags = await db.collection(`accounts/${accountId}/search`).where("type", "==", "searchItem_tag").where("project", "==", projectId).get()
    // //console.log("Tags: ", tags)
    return await Promise.all(tags.docs.map(async doc => await doc.data()))
  } catch (error) {
    //console.log("Error retrieving tags: ", error)
  }
}

// Tag search for a project, return all tags for a project with the respective group and board
export async function searchTagsForProject({accountId, projectId}) {
  const tags = await db.collection(`accounts/${accountId}/search`).where("type", "==", "searchItem_tag").where("project", "==", projectId).get();
  return tags.docs.map(doc => doc.data());
}

// search by title
export async function searchUsingText({accountId, text, project = null, type = null}) { 
  try {
    const searchText = text?.toLowerCase();
    
    let query = db.collection(`accounts/${accountId}/search`);

    // Add all provided filters to the Firestore query
    if (project) {
      query = query.where("project", "==", project);
    }
    
    if (type) {
      query = query.where("type", "==", type);
    }
    
    if (searchText) {
      // First get the range of possible matches
      query = query.where("titleLowerCase", ">=", searchText)
                  .where("titleLowerCase", "<=", searchText + '\uf8ff')
                  .limit(10);
    }

    const searchResults = await query.get();

    return searchResults.docs.map(doc => doc.data());
    
  } catch (error) {
    console.log("Error searching: ", error);
    return [];
  }
}

export async function searchForTags({accountId, text}) { 
  // add projectId filter
  //console.log("Searching for tags with text: ", text, " in account: ", accountId)
  try {
    const searchResults = await db.collection(`accounts/${accountId}/search`)
      .where("type", "==", "searchItem_tag")
      .where("title", ">=", text)
      .where("title", "<=", text + "\uf8ff")
      .limit(10)
      .get()
    // //console.log("Search results: ", searchResults)
    return searchResults.docs.map(doc => doc.data())
  } catch (error) {
    //console.log("Error searching for tags: ", error)
  }
}

export async function retrieveAll({accountId, projectId}) { 
  //console.log("Retrieving all search items for account: ", accountId)
  try {
    let searchResults;
    if (projectId) {
      // If projectId is provided, filter by projectId
      searchResults = await db.collection(`accounts/${accountId}/search`).where("project", "==", projectId).limit(10).get()
    } else {
      // If projectId is not provided, don't filter by projectId
      searchResults = await db.collection(`accounts/${accountId}/search`).limit(10).get()
    }
    // //console.log("Search results: ", searchResults)
    return searchResults.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    //console.log("Error searching: ", error)
  }
}

// Retrieve all search items for a specific project and type
export async function retrieveAllForProjectAndType({accountId, projectId, type, propertyField = null, propertyValue = null}) { 
  try {
    // TYPEZ
    let transposedType = "";
    if (type === "persona") {
      transposedType = "searchItem_persona"
    } else if (type === "boardGroup") {
      transposedType = "searchItem_boardGroup"
    } else if (type === "tag") {
      transposedType = "searchItem_tag"
    } else if (type === "note") {
      transposedType = "searchItem_note"
    } else if (type === "userJourney") {
      transposedType = "searchItem_userJourney"
    } else if (type === "userFlow") {
      transposedType = "searchItem_userFlow"
    } else if (type === "template") {
      transposedType = "searchItem_template"
    } else if (type === "sitemap") {
      transposedType = "searchItem_sitemap"
    } else if (type === "feature") {
      transposedType = "searchItem_feature"
    } else if (type === "content") {
      transposedType = "searchItem_content"
    } else if (type === "pageset") {
      transposedType = "searchItem_pageset"
    } else if (type === "page") {
      transposedType = "searchItem_page"
    } else {
      //console.log("There is no type with this type", type)
      return null;
    }
    let searchResults;
    if (type === "note") {
      searchResults = await db.collection(`accounts/${accountId}/search`)
        .where("project", "==", projectId)
        .where("type", "==", transposedType)
        .where("bin", "==", false)
        .limit(10)
        .get()
    } else {
      if (propertyField && propertyValue) {
        searchResults = await db.collection(`accounts/${accountId}/search`)
          .where("project", "==", projectId)
          .where("type", "==", transposedType)
          .where(propertyField, "==", propertyValue)
          .limit(10)
          .get()
      } else {
        searchResults = await db.collection(`accounts/${accountId}/search`)
          .where("project", "==", projectId)
          .where("type", "==", transposedType)
          .limit(10)
          .get()
      }      
    }
    
    // //console.log("Search results: ", searchResults)
    return searchResults.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    //console.log("Error searching: ", error)
  }
}
