import React, { useState, createContext, useContext, useCallback } from 'react';
import { useAuth } from '../../authContext'; 
import saveNoteToFirestore, { 
  retrieveVersionsOfNote, 
  deleteVersionOfNote, 
  retrieveNotesFromFirestore, 
  retrieveBinnedNotesFromFirestore, 
  newNote, 
  deleteNote, 
  pinNote, 
  unBinNote, 
  makeNotePrivate, 
  makeNotePublic, 
  getNoteContent, 
  getNoteCount, 
  executeFilteredQuery 
} from '../database/notes-db.js';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { set } from 'date-fns';
import { useLoading } from './loadingContext.js';
import { BASE_PRIVATEAPP_URL } from '../../redirects/index.js';
// import { useTags } from './tagsContext.js';

// Empty editor
const emptyEditorVar = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}'

// Create the context
const NotesContext = createContext();

// Create a provider component
export function NotesProvider({ children }) {
    const { currentUser } = useAuth()
    // const { setSelectedTags } = useTags()
    const { accountId, selectedProjectId } = useParams();
    const [notes, setNotes] = useState([]);
    const [editor, setEditor] = useState(null);
    const [noteEditorOpen, setNoteEditorOpen] = useState(false);
    const [html,setHtml] = useState(null);
    const [updateLinkModal, setUpdateLinkModal] = useState(false);
    const {running, setRunning} = useLoading();
    const [saveTriggered, setSaveTriggered] = useState(false);
    const [updatingFilters, setUpdatingFilters] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const noteId = queryParams.get('noteId');
    const refresh = queryParams.get('refresh');
    const [selectedNote, setSelectedNote] = React.useState([]);
    const [editorState, setEditorState] = React.useState();
    const [editorStateRaw, setEditorStateRaw] = React.useState();
    const [noteTitle, setNoteTitle] = React.useState('');
    const [notesPage, setNotesPage] = React.useState(false);
    const [binPage, setBinPage] = React.useState(false);
    const [filteredNotes, setFilteredNotes] = React.useState(false);
    const [searchFilteredNotes, setSearchFilteredNotes] = React.useState([]);
    const [newSelection, setNewSelection] = React.useState(false);
    const [ pinNoteLocal, setPinNoteLocal ] = React.useState(false);
    const [ passedPasswordCheck, setPassedPasswordCheck ] = React.useState(null);
    const [ currentUserNotesFiltered, setCurrentUserNotesFiltered ] = React.useState(false);
    const [ currentUserNotesFilteredCount, setCurrentUserNotesFilteredCount ] = React.useState(0);
    const [ userFilters, setUserFilters ] = React.useState({});
    const [ allowEditing, setAllowEditing ] = React.useState(false);
    const [ tagsFiltered, setTagsFiltered ] = React.useState(false);
    const [ filteredTags, setFilteredTags ] = React.useState([]);
    const [ pendingTag, setPendingTag ] = React.useState([]);
    
    // Pagination
    const [ hasMoreNotes, setHasMoreNotes ] = React.useState(true);
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ lastVisibleDoc, setLastVisibleDoc ] = React.useState(null);
    
    const editorRef = React.useRef();
    const titleRef = React.useRef();
    const editorInnerRef = React.useRef();

    // Filters
    const [ notesFiltered, setNotesFiltered ] = useState(false)
    const [ uploadsFiltered, setUploadsFiltered ] = useState(false)
    const [ linksFiltered, setLinksFiltered ] = useState(false)

    // Modals
    const [ multiDeleteModal, setMultiDeleteModal ] = React.useState(false);
    const [confirmMultiDelete, setConfirmMultiDelete] = React.useState(false);
    // Snackbar user feedback states
    const [showSnackbar, setShowSnackbar] = React.useState(false)
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('neutral')
    const [snackbarMsg, setSnackbarMsg] = React.useState('')

    const [totalNoteCount, setTotalNoteCount] = useState(0);

    const handleFilterCreatedByCurrentUser = async () => {
      setCurrentUserNotesFiltered(!currentUserNotesFiltered);
    };

    const handleFilterByUser = async (userId) => {
      setUserFilters(prevFilters => ({
        ...prevFilters,
        [userId]: !prevFilters[userId]
      }));
    };

    const handleFilterNotes = async () => {
      setNotesFiltered(!notesFiltered);
    };

    const handleFilterUploads = async () => {
      setUploadsFiltered(!uploadsFiltered);
    };

    const handleFilterLinks = async () => {
      setLinksFiltered(!linksFiltered);
    };

    const handleFilterTags = async () => {
      setFilteredTags(pendingTag);
      setTagsFiltered(pendingTag.length > 0);
    };

    const handlePasswordCheck = (password, content) => {
      if (password === content.privatePassword) {
        setPassedPasswordCheck(true)
      } else {
        setPassedPasswordCheck(false)
      }
    }

    const handleDeleteNote = async ({ noteId }) => {  
        //console.log("Handle DELETE note", noteId)
        setRunning(true)
        const result = await deleteNote( noteId, accountId )
        console.log("Result of deleteNote", result)
        getAndSetData( { setNewCurrentNote: true, sortByPinned: false } );
        
        setSnackbarSeverity('success')
        setSnackbarMsg('Note successfully deleted')
        setShowSnackbar(true)
        setRunning(false)
    }

    const handleDeleteNotes = async ({ notesToDelete }) => {
        setRunning(true)
        for (const note of notesToDelete) {
          const noteId = note.id;
    
          console.log("Handle DELETE note", note)
          // TO DO: Impement delete note
          // await deleteNote( noteId, accountId)
          // Double check that the binned flag is set to true
          if (note.bin) {
            const result = await deleteNote( noteId, accountId )
            //console.log("Result of deleteNote", result)
          } else {
            console.log("Note is not binned, skipping delete")
          }
        };
    
        setNotes([])
        handleNewSelectedNote({},true)
        setSnackbarSeverity('success')
        setSnackbarMsg('Notes have been successfully deleted')
        setShowSnackbar(true)
        setRunning(false)
    }
    
    const handleRestoreNote = async ({ noteId }) => {
        //console.log("Handle RESTORE note", noteId)
        setRunning(true)
        const result = await unBinNote( noteId, accountId )
        //console.log("Result of unBinNote", result)
        handleNewSelectedNote(notes[0],true)
        getAndSetData( { setNewCurrentNote: true, sortByPinned: false } );
        setSnackbarSeverity('success')
        setSnackbarMsg('Note successfully restored')
        setShowSnackbar(true)
        setRunning(false)
    }

    const handleMakeNotePrivate = async ({ noteId, password }) => {
      setRunning(true)
      await makeNotePrivate({ noteId, accountId, password })
      await getAndSetData( { setNewCurrentNote: true, sortByPinned: false } );
      setSnackbarSeverity('success')
      setSnackbarMsg('Note successfully made private')
      setShowSnackbar(true)
      setRunning(false)
    }

    const handleMakeNotePublic = async ({ noteId }) => {
      setRunning(true)
      await makeNotePublic({ noteId, accountId })
      await getAndSetData( { setNewCurrentNote: true } );
      setSnackbarSeverity('success')
      setSnackbarMsg('Note successfully made public')
      setShowSnackbar(true)
      setRunning(false)
    }

    const handleCreateNewNote = async () => {
        try {
          setRunning(true)
          await newNote({ user: currentUser, accountId: accountId, selectedProjectId: selectedProjectId }).then((result) => {
            //console.log("NEW NOTE")
            setNoteTitle(result.noteTitle)
            handleNewSelectedNote(result,true)
            getAndSetData( { setNewCurrentNote: false, sortByPinned: false } );
          })
          // Open edit modal
          setNoteEditorOpen(true)
          setRunning(false)
        } catch(error) {
          //console.log("error creating note", error)
          setRunning(false)
        }
    }

    const fetchNoteVersions = async ({ accountId, noteId }) => {
      try {
        const versions = await retrieveVersionsOfNote({ noteId: noteId, accountId: accountId })
        if (versions && versions.length > 0) {
          return versions
        } else {
          return []
        }
      } catch(error) {
        //console.log("error fetching note versions", error)
      }
    }

    function debounce(callback, wait) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(function () { callback.apply(this, args); }, wait);
        };
    }

    const debouncedKeyUp = useCallback(debounce(() => {
      //console.log("Key up");
      setSaveTriggered(true);
    }, 5000), []);

    const updatesToFilter = async () => {
      setUpdatingFilters(true);
      await executeFilteredQuery({
        accountId,
        selectedProjectId,
        notesFiltered,
        uploadsFiltered,
        linksFiltered,
        userFilters,
        tagsFiltered,
        pendingTag,
        setFilteredNotes,
        setSearchFilteredNotes,
        setCurrentUserNotesFilteredCount,
      });
      setUpdatingFilters(false);
    }

    React.useEffect(() => {
      if (!updatingFilters) {
        updatesToFilter();
      }
    }, [notesFiltered, uploadsFiltered, linksFiltered, userFilters, tagsFiltered, pendingTag])

    React.useEffect(() => {
      const editorElement = editorRef.current;
      const titleElement = titleRef.current;

      if (editorElement) {
        editorElement.addEventListener('keyup', debouncedKeyUp);
        //editorElement.addEventListener('blur', setSaveTriggered(true));
      }

      if (titleElement) {
        // titleElement.addEventListener('keyup', debouncedKeyUp);
        //titleElement.addEventListener('blur', setSaveTriggered(true));
      }

      // Make sure to remove the event listeners when the component unmounts
      return () => {
        if (editorElement) {
          editorElement.removeEventListener('keyup', debouncedKeyUp);
          editorElement.removeEventListener('blur', debouncedKeyUp);
        }

        // if (titleElement) {
        //   titleElement.removeEventListener('keyup', debouncedKeyUp);
        //   titleElement.removeEventListener('blur', debouncedKeyUp);
        // }
      };
    }, [editorRef.current, titleRef.current]);

    const handlePinNote = async ({ note }) => {
      console.log("handlePinNote note", note)
      await handleSave({ note: note, setNewCurrentNote: true, pinNote: !note.pinned })
    }

    const handleSaveTranscript = async ({ note }) => {
      console.log("handleSaveTranscript note", note)
      try {
        if (editorState !== emptyEditorVar) {
          await saveNoteToFirestore({ 
            selectedNote: note, 
            state: editorState, 
            title: note.noteTitle, 
            accountId, 
            selectedProjectId,
          });
          await getAndSetData( { setNewCurrentNote: false, sortByPinned: false } );
        } else {
          console.log("Not saving as editor is empty");
        }
      } catch(error) {
        console.log("error saving transcript", error)
      } finally {
      }
    }
    
    // Note: pendingTag is passed as useContext cant access tags due to the heirarchical structure of...
    // nested contexts see PrivateSite/index.js for more info
    const handleSave = async ({ note, setNewCurrentNote = true, tagsChanged = false, pendingTag = [], pinNote = false, sortByPinned = false }) => {
      console.log("handleSave note", note)
      console.log("setNewCurrentNote", setNewCurrentNote)
      const pinNoteActual = pinNote !== null ? pinNote : note.pinned;
      const actualTags = tagsChanged ? pendingTag : note.tags
      const currentTitle = noteTitle === "" ? note.noteTitle : noteTitle;
      try {
        setRunning(true)
        if (editorState !== emptyEditorVar) {
          await saveNoteToFirestore({ 
            selectedNote: note, 
            state: editorState, 
            title: currentTitle, 
            accountId, 
            selectedProjectId, 
            pinned: pinNoteActual,
            tags: actualTags
          });
          await getAndSetData( { setNewCurrentNote: setNewCurrentNote, sortByPinned } );
          setRunning(false);
        } else {
          console.log("Not saving as editor is empty");
          setRunning(false);
        }
      } catch(error) {
        console.log("error saving note", error)
      } finally {
        setSaveTriggered(false)
      }
    }
    // For manually setting a new selected note on local data
    const handleNewSelectedNote = async (note, setNewCurrentNote = false) => {

      if (note && note.id) {
          setSelectedNote(prevState => ({
              ...prevState,
              ...note
          }))
      } else {
          setSelectedNote({})
      }
        
    }

    const fetchData = async () => {
      try {
        if (binPage) {
          console.log("Fetching binned notes");
          const { notes, lastVisible, hasMore } = await retrieveBinnedNotesFromFirestore({
            accountId,
            selectedProjectId,
            limit: 20,
            lastDoc: null
          });
          
          setHasMoreNotes(hasMore);
          setLastVisibleDoc(lastVisible);
          return notes;
        } else {
          console.log("Fetching regular notes");
          const { notes, lastVisible, hasMore } = await retrieveNotesFromFirestore({
            accountId,
            selectedProjectId,
            limit: 20,
            lastDoc: null
          });
          
          setHasMoreNotes(hasMore);
          setLastVisibleDoc(lastVisible);
          return notes;
        }
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
        throw error;
      }
    };

    const fetchMoreNotes = async () => {
      if (!hasMoreNotes || isLoading) return;
      
      setIsLoading(true);
      try {
        const { notes, lastVisible, hasMore } = await retrieveNotesFromFirestore({
          accountId,
          selectedProjectId,
          limit: 20,
          lastDoc: lastVisibleDoc
        });
        
        setNotes(prevNotes => [...prevNotes, ...notes]);
        setHasMoreNotes(hasMore);
        setLastVisibleDoc(lastVisible);
      } catch (error) {
        console.error('Error fetching more notes:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const getAndSetData = async ({ setNewCurrentNote = false, sortByPinned = false }) => {
        try {
            //console.log("REFETCHING NOTES")
            const data = await fetchData();
            
            if (sortByPinned) {
              // Resort the notes array to show pinned notes first when used for pinned listings
              // This isnt required for saving functions generally, as the first item in the array is used when setNewCurrentNote is true
              // Thus need to select the correct note based on the context of the call
              data.sort((a, b) => {
                if (a.pinned && !b.pinned) {
                  return -1;
                } else if (!a.pinned && b.pinned) {
                  return 1;
                } else {
                  return 0;
                }
              });
            }

            setNotes(data);

            // Select the latest note from the sorted fetched data (see sortByPinned and retrieveNotesFromFirestore function in notes-db.js)
            if (setNewCurrentNote) {
              loadNoteContent(data[0].id).then(content => {
                if (content) {
                  handleNewSelectedNote({ ...data[0], ...content });
                  setEditorStateRaw(content.editorState)
                }
              });
            }

            return data
            
        } catch (error) {
          // Handle the error if needed
        } finally {
          setRunning(false)
        }
    };

    const handleOpenNoteInEditor = (note) => {

      if (note.type === "note" || note.type === "upload") {
        setSelectedNote(note, true);
        // set title to note title
        setNoteTitle(note.noteTitle)

        setNoteEditorOpen(true)
      }
      else {
        setSelectedNote(note);
        setNoteTitle(note.noteTitle)
        setUpdateLinkModal(true);
      }
    }

    const handleEmptyBin = async () => {
        setMultiDeleteModal(true);
    }

    const updateTitle = (e) => {
      setNoteTitle(e.target.value)
    }

    const onEditorChange = (editorState) => {
      setEditorStateRaw(editorState)
      // Call toJSON on the EditorState object, which produces a serialization safe string
      const editorStateJSON = editorState.toJSON();
      // However, we still have a JavaScript object, so we need to convert it to an actual string with JSON.stringify
      setEditorState(JSON.stringify(editorStateJSON));
    }

    const handleFetchNotesAndGetDataForSelectedNote = async (setNewCurrentNote = false, sortByPinned = true) => {
      if (!running) {
        try {
          const data = await getAndSetData({ setNewCurrentNote: setNewCurrentNote, sortByPinned });
          
          // Add null check for data before using find
          if (data && Array.isArray(data)) {
            const matchingNote = data.find(note => note.id === noteId);

            if (matchingNote && matchingNote.id && matchingNote.id !== selectedNote.id) {
              navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/' + '?noteId=' + noteId);
              handleNewSelectedNote(matchingNote, true);
            }
          } else {
            console.warn('No data returned from getAndSetData');
          }
        } catch (error) {
          console.error('Error fetching notes:', error);
        }
      }
    }

    const handleUpdateNoteProject = async ({ newProjectId, sortByPinned = false, content }) => {
      setRunning(true)
      await saveNoteToFirestore({ 
        selectedNote: content,
        state: content.editorState,
        title: content.noteTitle,
        accountId: accountId,
        selectedProjectId: newProjectId,
        tags: content.tags
      })
      await getAndSetData( { setNewCurrentNote: true, sortByPinned } );
      setRunning(false)
    }

    React.useEffect(() => {
      if (!running && !binPage) {  // Only fetch regular notes if not on bin page
        getAndSetData({ setNewCurrentNote: false, sortByPinned: true });
        fetchNoteCount();
      }
    }, []);

    React.useEffect(() => {

      // Selected note is outdated despite remote source being updated, when the refresh=true query param is present, set note again
      if (!running) {
        handleBinPageTransition();
        getAndSetData( { setNewCurrentNote: true, sortByPinned: true } );
      }
      
    }, [refresh]);

    React.useEffect(() => {
        //console.log("editorRef", editorRef)
      },[editorRef])
    
    React.useEffect(() => {
        if (selectedProjectId) {
          //console.log("REFETCHING NOTES BECAUSE OF NEW SELECTED PROJECT")
          getAndSetData( { setNewCurrentNote: true, sortByPinned: true } );
        }
    },[selectedProjectId])

    React.useEffect(() => {
        //console.log("saveTriggered", saveTriggered)
        if (saveTriggered) {
          if (noteTitle !== "") {
              //console.log("Running save")
              handleSave({ note: selectedNote, setNewCurrentNote: false })
          }
        } else {
          //console.log("cant save at the moment as another status of current request is ")
        }
    },[saveTriggered])

    React.useEffect(() => {
        // Set first note in array on load, else respect the current selectedNote each time the notes array is updated
        if (notes && notes.length > 0 && selectedNote && selectedNote.length == 0) {
            const firstNote = notes[0];
            handleNewSelectedNote(firstNote, true);
            setNoteTitle(firstNote.noteTitle);
            
            // Load the content for the first note
            loadNoteContent(firstNote.id).then(content => {
                if (content) {
                    setSelectedNote({ ...firstNote, ...content });
                }
            });
        }

        setSearchFilteredNotes(notes);

        console.log("notes and binPage is ", notes, binPage)
    }, [notes]);

    React.useEffect(() => {
        //console.log("BIN PAGE !", binPage)
        if (binPage || notesPage) {
          getAndSetData( { setNewCurrentNote: true } );
        }
    },[binPage])

    React.useEffect(() => {
        setBinPage(location.pathname.endsWith('/bin'))
        const queryParams = new URLSearchParams(location.search);
        const refreshPresent = queryParams.get('refresh') === 'true';
        setNotesPage(refreshPresent);
    },[location]);

    React.useEffect(() => {
      if (noteId) {
          //console.log("notedId changed", noteId)
          // Refetch notes to ensure data is up to date
          handleFetchNotesAndGetDataForSelectedNote(true)
      }
    },[noteId])

    React.useEffect(() => {
        if (confirmMultiDelete) {
          setMultiDeleteModal(false);
          handleDeleteNotes({ notesToDelete: notes });
        }
    },[confirmMultiDelete]);

    // console.log("SELECTED NOTE", selectedNote)

    React.useEffect(() => {
      
      if (currentUser && selectedNote) {
        const canEdit = checkNoteEditPermissions({
          content: selectedNote,
          currentUser,
          isEditorOpen: noteEditorOpen
        });
        setAllowEditing(canEdit);
      }
      
      if (selectedNote.private) {
        setAllowEditing(false);
        setPassedPasswordCheck(null);
      }
    }, [currentUser, selectedNote, noteEditorOpen]);

    const checkNoteEditPermissions = ({ content, currentUser, isEditorOpen = false }) => {
      if (!currentUser || !content) return false;
      
      const isOwner = currentUser._delegate.uid === content.createdBy;
      const canEdit = isOwner && isEditorOpen && !content.private;
      
      return canEdit;
    };

    const checkNoteEditPermissionsWithoutEditor = ({ content, currentUser }) => {
      if (!currentUser || !content) return false;
      const isOwner = currentUser._delegate.uid === content.createdBy;
      const canEdit = isOwner && !content.private;
      return canEdit;
    }

    const loadNoteContent = async (noteId) => {
      try {
        const content = await getNoteContent({ 
          noteId, 
          accountId 
        });
        return content;
      } catch (error) {
        console.error('Error loading note content:', error);
        return null;
      }
    };

    // Effect to initialize and update count when notes change
    React.useEffect(() => {
      if (!filteredNotes) {
        fetchNoteCount();
      }
    }, [selectedProjectId, filteredNotes]);

    const fetchNoteCount = async () => {
      try {
        const count = await getNoteCount({ 
          accountId, 
          selectedProjectId 
        });
        setTotalNoteCount(count);
        if (!filteredNotes) {
          setCurrentUserNotesFilteredCount(count);
        }
      } catch (error) {
        console.error("Error fetching note count:", error);
      }
    };

    const handleBinPageTransition = () => {
      setSelectedNote([]);
      setNotes([]);
      // Then let the effects handle fetching the appropriate notes
    };

    React.useEffect(() => {
      if (binPage) {
        console.log("Bin page changed, fetching binned notes");
        getAndSetData({ setNewCurrentNote: true, sortByPinned: false });
      }
    }, [binPage]);

    const value = {
        notes,
        setNotes,
        running,
        html,
        setHtml,
        setRunning,
        noteId,
        allowEditing,
        setAllowEditing,
        selectedNote,
        filteredNotes,
        setFilteredNotes,
        searchFilteredNotes,
        checkNoteEditPermissions,
        setSearchFilteredNotes,
        setSelectedNote,
        editorState,
        setEditorState,
        handleMakeNotePrivate,
        handleMakeNotePublic,
        onEditorChange,
        checkNoteEditPermissionsWithoutEditor,
        noteTitle,
        setNoteTitle,
        fetchData,
        getAndSetData,
        handleCreateNewNote,
        handleNewSelectedNote,
        deleteVersionOfNote,
        handleDeleteNote,
        handleDeleteNotes,
        handleRestoreNote,
        handleEmptyBin,
        handleSave,
        updateTitle,
        confirmMultiDelete,
        setConfirmMultiDelete,
        multiDeleteModal,
        setMultiDeleteModal,
        handleFetchNotesAndGetDataForSelectedNote,
        editorRef,
        editorInnerRef,
        titleRef,
        binPage,
        showSnackbar,
        setShowSnackbar,
        snackbarSeverity,
        setSnackbarSeverity,
        snackbarMsg,
        setSnackbarMsg,
        setSaveTriggered,
        fetchNoteVersions,
        handleOpenNoteInEditor,
        setNoteEditorOpen,
        noteEditorOpen,
        handlePinNote,
        updateLinkModal, 
        setUpdateLinkModal,
        handleUpdateNoteProject,
        passedPasswordCheck,
        setPassedPasswordCheck,
        handlePasswordCheck,
        newSelection, setNewSelection,
        notesFiltered, setNotesFiltered,
        uploadsFiltered, setUploadsFiltered,
        linksFiltered, setLinksFiltered,
        handleFilterNotes,
        handleFilterUploads,
        handleSaveTranscript,
        handleFilterLinks,
        handleFilterCreatedByCurrentUser,
        handleFilterByUser,
        currentUserNotesFiltered,
        currentUserNotesFilteredCount,
        userFilters,
        setUserFilters,
        tagsFiltered,
        setTagsFiltered,
        handleFilterTags,
        filteredTags,
        setFilteredTags,
        pendingTag,
        setPendingTag,
        hasMoreNotes,
        fetchMoreNotes,
        isLoading,
        loadNoteContent,
        editor,
        setEditor,
        editorStateRaw,
        totalNoteCount,
        fetchNoteCount,
    };

    return <NotesContext.Provider value={value}>{children}</NotesContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useNotes() {
    return useContext(NotesContext);
}

