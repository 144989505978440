import React from 'react';
import { Tooltip } from '@mui/material';
import {
  AssignmentRounded as NoteIcon,
  Label as TagIcon,
  PersonAdd as PersonaIcon,
  Route as JourneyIcon,
  AddReaction as FeatureIcon,
  AccountTree as SitemapIcon,
  Article as PageIcon,
  Web as PagesetIcon,
  Link as ExternalIcon
} from '@mui/icons-material';

const getLinkIcon = (type) => {
  switch (type) {
    case 'note':
    case 'searchItem_note':
      return (
        <Tooltip title="Note">
          <NoteIcon sx={{ fontSize: 16 }} />
        </Tooltip>
      );
    case 'searchItem_tagBoard':
    case 'tagBoard':
    case 'searchItem_boardGroup':
    case 'tagGroup':
    case 'searchItem_tag':
    case 'tag':
      return (
        <Tooltip variant="plain" title="Tag">
          <TagIcon sx={{ fontSize: 16 }} />
        </Tooltip>
      );
    case 'searchItem_persona':
    case 'persona':
      return (
        <Tooltip variant="plain" title="Persona">
          <PersonaIcon sx={{ fontSize: 16 }} />
        </Tooltip>
      );
    case 'searchItem_journey':
    case 'journey':
      return (
        <Tooltip variant="plain" title="Journey">
          <JourneyIcon sx={{ fontSize: 16 }} />
        </Tooltip>
      );
    case 'searchItem_feature':
    case 'feature':
      return (
        <Tooltip variant="plain" title="Feature">
          <FeatureIcon sx={{ fontSize: 16, display: 'inline-flex' }} />
        </Tooltip>
      );
    case 'searchItem_sitemap':
    case 'sitemap':
      return (
        <Tooltip variant="plain" title="Sitemap">
          <SitemapIcon sx={{ fontSize: 16 }} />
        </Tooltip>
      );
    case 'searchItem_page':
    case 'page':
      return (
        <Tooltip variant="plain" title="Page">
          <PageIcon sx={{ fontSize: 16 }} />
        </Tooltip>
      );
    case 'searchItem_pageset':
    case 'pageset':
      return (
        <Tooltip variant="plain" title="Pageset">
          <PagesetIcon sx={{ fontSize: 16 }} />
        </Tooltip>
      );
    default:
      return (
        <Tooltip variant="plain" title="External">
          <ExternalIcon sx={{ fontSize: 16 }} />
        </Tooltip>
      );
  }
};

export const LinkIcon = ({ type }) => {
  console.log("1. LinkIcon received type:", type);
  
  if (!type) {
    console.warn("2. No type provided to LinkIcon");
    return (
      <Tooltip variant="plain" title="External">
        <ExternalIcon sx={{ fontSize: 16 }} />
      </Tooltip>
    );
  }

  console.log("3. About to call getLinkIcon with type:", type);
  const icon = getLinkIcon(type);
  console.log("4. getLinkIcon returned:", icon);
  
  return (
    <span className="debug-icon-wrapper" style={{ display: 'inline-flex', alignItems: 'center' }}>
      {icon}
    </span>
  );
};

export function SafeLinkIcon({ type }) {
  try {
    console.log("1. LinkIcon received type:", type);
    return <LinkIcon type={type} />;
  } catch (error) {
    console.error("Error rendering LinkIcon:", error);
    return null;
  }
}

export default LinkIcon;
