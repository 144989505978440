import database from '../../configs/firebaseConfig'; 
const db = database.firestore(); // Access Firestore database

// Add new connection to Firestore
export async function addConnectionToFirestore({ accountId, project, connection, user }) {
  console.log("addConnectionToFirestore called with connection object", connection)
  try {
    const docRef = await db.collection(`accounts/${accountId}/connections`).add({
      ...connection,
      project: project,
      created: new Date(),
      updated: new Date(),
      user: user._delegate.uid
    });
    const doc = await docRef.get();
    const data = doc.data()
    return { ...data, id: doc.id }
  } catch(error) {
    console.error("Error adding connection to Firestore:", error);
  }
}

// Retrieve all connections for project
export async function fetchConnectionsFromFirestore({ accountId, project }) {
  try {
    const connectionsRef = await db.collection(`accounts/${accountId}/connections`).where("project", "==", project).get();
    const connections = connectionsRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    return { connections }
  }
  catch (error) {
    console.error("Error fetching connections from Firestore:", error);
  }
}

// Retrieve all connections for fromId
export async function fetchConnectionsFromFirestoreByFromId({ accountId, project, fromId }) {
  try {
    const connectionsRef = await db.collection(`accounts/${accountId}/connections`).where("project", "==", project).where("fromId", "==", fromId).get();
    const connections = connectionsRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    return { connections }
  }
  catch (error) {
    console.error("Error fetching connections from Firestore:", error);
  }
}