import React from 'react';
import ReactDOM from 'react-dom';
import {$createTextNode, ElementNode, $applyNodeReplacement, $createParagraphNode} from 'lexical';
import {addClassNamesToElement} from '@lexical/utils';
import { LinkIcon, SafeLinkIcon } from '../../../Icons/GetLinkIcon';
import './customlinknode.css';
import { render } from 'react-dom/client';

export class CustomLinkNode extends ElementNode {
  static getType() {
    return 'custom-link';
  }

  static clone(node) {
    return new CustomLinkNode(
      node.__url,
      {
        rel: node.__rel,
        target: node.__target,
        title: node.__title,
        linkType: node.__linkType,
      },
      node.__key,
    );
  }

  constructor(url, attributes = {}, key) {
    super(key);
    this.__url = url;
    this.__rel = attributes.rel;
    this.__target = attributes.target;
    this.__title = attributes.title;
    this.__linkType = attributes.linkType || 'external';
  }

  // Getters and setters for all properties
  getURL() {
    return this.__url;
  }

  setURL(url) {
    const self = this.getWritable();
    self.__url = url;
  }

  getLinkType() {
    return this.__linkType;
  }

  setLinkType(linkType) {
    const self = this.getWritable();
    self.__linkType = linkType;
  }

  getRel() {
    return this.__rel;
  }

  getTarget() {
    return this.__target;
  }

  getTitle() {
    return this.__title;
  }

  // Required methods for serialization
  exportJSON() {
    return {
      type: 'custom-link',
      url: this.__url,
      rel: this.__rel,
      target: this.__target,
      title: this.__title,
      linkType: this.__linkType,
      version: 1,
      children: super.exportJSON().children,
    };
  }

  static importJSON(serializedNode) {
    const node = $createCustomLinkNode(
      serializedNode.url,
      {
        rel: serializedNode.rel,
        target: serializedNode.target,
        title: serializedNode.title,
        linkType: serializedNode.linkType,
      },
    );
    
    return node;
  }

  createDOM(config) {
    const element = document.createElement('span');
    element.className = `custom-link-wrapper link-type-${this.__linkType}`;
    return element;
  }

  updateDOM(prevNode, dom) {
    const shouldUpdate = 
      prevNode.__url !== this.__url ||
      prevNode.__linkType !== this.__linkType ||
      prevNode.__title !== this.__title;

    if (shouldUpdate) {
      if (prevNode.__url !== this.__url) {
        dom.href = this.__url;
      }
      
      if (prevNode.__linkType !== this.__linkType) {
        dom.className = dom.className.replace(
          `link-type-${prevNode.__linkType}`,
          `link-type-${this.__linkType}`
        );
        
        const iconContainer = dom.querySelector('.custom-link-icon');
        if (iconContainer) {
          const root = ReactDOM.createRoot(iconContainer);
          root.render(<LinkIcon type={this.__linkType} />);
        }
      }

      if (prevNode.__title !== this.__title) {
        dom.title = this.__title;
      }
    }

    return false;
  }

  isInline() {
    return true;
  }

  canBeEmpty() {
    return false;
  }

  canInsertTextBefore() {
    return false;
  }

  canInsertTextAfter() {
    return false;
  }

  insertNewAfter(selection, restoreSelection = true) {
    const textNode = $createTextNode('');
    const paragraphNode = $createParagraphNode();
    paragraphNode.append(textNode);
    this.insertAfter(paragraphNode, restoreSelection);
    return textNode;
  }
}

export function $createCustomLinkNode(url, attributes = {}) {
  return $applyNodeReplacement(new CustomLinkNode(url, attributes));
}

export function $isCustomLinkNode(node) {
  return node instanceof CustomLinkNode;
}