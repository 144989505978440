import database, { storage } from '../../configs/firebaseConfig'; 
import { checkTeamMembership } from './utilities-db';
import { ref, getStorage, uploadBytes, listAll, getDownloadURL } from "firebase/storage"
import { v4 } from 'uuid';
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  getCountFromServer, 
  doc, 
  updateDoc, 
  arrayUnion, 
  getDocs, 
  writeBatch 
} from "firebase/firestore";

const db = database.firestore(); // Access Firestore database

export const createSpreadsheet = async ({ noteRef, userId, accountId, fileName, headerRowIndex = 0 }) => {
  console.log('createSpreadsheet', userId, fileName, noteRef.id)
  console.log('headerRowIndex', headerRowIndex)
  // Trigger the Cloud Function by creating a processing request
  await db.collection(`accounts/${accountId}/spreadsheet_processing`).add({
    spreadsheetId: noteRef.id,
    storageUrl: noteRef.downloadURL,
    noteRef: noteRef,
    fileName,
    userId,
    accountId,
    status: 'pending',
    headerRowIndex,
    createdAt: new Date()
  });
}

export const getSpreadsheet = async (accountId, spreadsheetId) => {
  const spreadsheet = await db.collection(`accounts/${accountId}/spreadsheets`).doc(spreadsheetId).get();
  return spreadsheet.data();
}

export const getSpreadsheetProcessing = async (accountId, spreadsheetId) => {
  const processing = await db.collection(`accounts/${accountId}/spreadsheet_processing`).where('spreadsheetId', '==', spreadsheetId).get();
  return processing.docs.map(doc => doc.data());
}

export const getSpreadsheetRows = async (accountId, spreadsheetId, limit = 20, lastDoc = null) => {
  let query = db.collection(`accounts/${accountId}/spreadsheet_rows`)
    .where('spreadsheetId', '==', spreadsheetId)
    .orderBy('rowIndex')
    .limit(limit);

  if (lastDoc) {
    query = query.startAfter(lastDoc);
  }

  const snapshot = await query.get();
  
  return {
    rows: snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })),
    lastDoc: snapshot.docs[snapshot.docs.length - 1]
  };
};

export const updateSpreadsheetHeaders = async (accountId, spreadsheetId, updatedHeaders) => {
  try {
    const spreadsheetRef = doc(db, `accounts/${accountId}/spreadsheets`, spreadsheetId);
    await updateDoc(spreadsheetRef, {
      headers: updatedHeaders
    });
    return true;
  } catch (error) {
    console.error('Error updating spreadsheet headers:', error);
    throw error;
  }
};

export const updateSpreadsheetRow = async (accountId, spreadsheetId, rowId, updatedRow) => {
  try {
    const rowRef = doc(db, `accounts/${accountId}/spreadsheet_rows`, rowId);
    
    // Remove any undefined or function values that can't be stored in Firestore
    const cleanRow = JSON.parse(JSON.stringify(updatedRow));
    
    await updateDoc(rowRef, {
      ...cleanRow,
      updatedAt: new Date()
    });
    
    return true;
  } catch (error) {
    console.error('Error updating spreadsheet row:', error);
    throw error;
  }
};

// Update the display header for all rows in a spreadsheet
export const updateSpreadsheetDisplayHeader = async (accountId, spreadsheetId, displayHeaderId) => {
  try {
    const db = getFirestore();
    const batch = writeBatch(db);
    
    // Get all rows for this spreadsheet
    const rowsRef = collection(db, `accounts/${accountId}/spreadsheet_rows`);
    const q = query(rowsRef, where('spreadsheetId', '==', spreadsheetId));
    const querySnapshot = await getDocs(q);
    
    // Update displayHeaderId for all rows in batch
    querySnapshot.forEach((doc) => {
      batch.update(doc.ref, { displayHeaderId });
    });
    
    // Commit the batch
    await batch.commit();
    return true;
  } catch (error) {
    console.error("Error updating display headers:", error);
    throw error;
  }
};

// Helper function to update cell tags
export const updateCellTags = async (accountId, rowId, cellId, tags) => {
  try {
    const rowRef = doc(db, `accounts/${accountId}/spreadsheet_rows`, rowId);
    
    await updateDoc(rowRef, {
      [`cells.${cellId}.tags`]: tags,
      updatedAt: new Date()
    });
    
    return true;
  } catch (error) {
    console.error('Error updating cell tags:', error);
    throw error;
  }
};

// Helper function to update cell highlight status
export const updateCellHighlight = async (accountId, rowId, cellId, highlighted) => {
  try {
    const rowRef = doc(db, `accounts/${accountId}/spreadsheet_rows`, rowId);
    
    await updateDoc(rowRef, {
      [`cells.${cellId}.highlighted`]: highlighted,
      updatedAt: new Date()
    });
    
    return true;
  } catch (error) {
    console.error('Error updating cell highlight status:', error);
    throw error;
  }
};

// Dont directly delete the spreadsheet, it will be handled in the Cloud Function if the note is deleted
// This includes the file deletion from storage and associated rows
export const deleteSpreadsheet = async (accountId, spreadsheetId) => {
  console.log('Dont directly delete the spreadsheet, it will be handled in the Cloud Function if the note is deleted')  
}

