import { Button, Box, Card, Typography, Link } from "@mui/joy";
import { useNavigate } from 'react-router-dom';

function ConnectionTitle({ fromTitle, toTitle, fromUrl, toUrl }) {
    const navigate = useNavigate();

    const handleClick = (url) => {
        navigate(url);
        console.log("clicked url", url)
    }
    
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Link 
            onClick={() => handleClick(fromUrl)}
            style={{ 
            textDecoration: 'none', 
            color: 'inherit',
            '&:hover': {
                textDecoration: 'underline'
            }
            }}
        >
            {fromTitle}
        </Link>
        <Typography>-</Typography>
        <Link 
            onClick={() => handleClick(toUrl)}
            style={{ 
            textDecoration: 'none', 
            color: 'inherit',
            '&:hover': {
                textDecoration: 'underline'
            }
            }}
        >
            {toTitle}
        </Link>
        </Box>
    );
}


function ConnectionCard({ data, mode = "fullpage" }) {
    return (
        <Card sx={{
            height: mode === "fullpage" ? 163 : 'auto',
            width: mode === "fullpage" ? '90%' : '100%',
            paddingBottom: 1,
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            border: 'none',
            boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',  
        }}>
            <ConnectionTitle 
                fromTitle={data.fromTitle}
                toTitle={data.toTitle}
                fromUrl={data.fromUrl}
                toUrl={data.toUrl}
            />
            <Typography level="body-sm">{ data && data.comment && data.comment }</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button variant="soft" color="neutral" size="sm">Add to insight</Button>
                {/* <Button variant="outlined" size="sm" color="neutral" sx={{ ml: 'auto' }}>Re-generate summary</Button> */}
            </Box>
        </Card>
    )
}

export default ConnectionCard;