import { Modal, ModalDialog, DialogTitle } from '@mui/joy';
import CreateConnection from '../../Connections/CreateConnection';

function CreateConnectionModal({ 
  source,
  setSource,
  sourceTitle,
  setSourceTitle,
  sourceType,
  setSourceType,
  target,
  setTarget,
  targetTitle,
  setTargetTitle,
  targetType,
  setTargetType,
  setComment,
  comment,
  createConnectionModal, 
  setCreateConnectionModal, 
  handleCreateConnection 
}) {

  return (
    <Modal open={createConnectionModal} onClose={() => setCreateConnectionModal(false)}>
      <ModalDialog size="md" sx={{ width: 600 }}>
        <DialogTitle>Create Connection</DialogTitle>
        <CreateConnection 
          setCreateConnectionModal={setCreateConnectionModal}
          handleCreateConnection={handleCreateConnection}
          source={source}
          setSource={setSource}
          sourceTitle={sourceTitle}
          setSourceTitle={setSourceTitle}
          target={target}
          setTarget={setTarget}
          targetTitle={targetTitle}
          setTargetTitle={setTargetTitle}
        />
      </ModalDialog>
    </Modal>
  )
}

export default CreateConnectionModal;