import React, { useState, useEffect, createContext, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../authContext'; 
import saveToFirestore, { newProject, retrieveFromFirestore, deleteProject } from '../database/projects-db';
import { setNewSelectedProject } from '../database/accounts-db';
import { retrieveBoardsFromFirestore } from '../database/tags-db';
import { useLoading } from './loadingContext.js';

// Create the context
const ProjectsContext = createContext();

// Create a provider component
export function ProjectsProvider({ children }) {
    const navigate = useNavigate();
    const { accountId, selectedProjectId } = useParams() 
    let location = useLocation();
    const auth = useAuth()
  
    const [ accountProjects, setAccountProjects ] = useState([])
    const [ projectTitleEditMode, setProjectTitleEditMode ] = useState(false)
    const [ projectModalOpen, setProjectModalOpen ] = useState(false)
    const [ selectedProject, setSelectedProject ] = useState()
    const [ projectName, setProjectName ] = useState('')
    const { running, setRunning } = useLoading();

    useEffect(() => {
        //console.log("Edit mode ", projectTitleEditMode)
    },[projectTitleEditMode])

    const handleProjectSelection = async (project) => {
        // Filter array of accountProjects where the value of accountProjects.id is equal to selectedProjectId
        const filteredData = accountProjects.filter(item => item.id === selectedProjectId);
        if (filteredData.length > 0) {
            const result = await setNewSelectedProject(auth.currentUser._delegate.uid, filteredData[0].id)
            setSelectedProject(filteredData[0])
        }
    }

    useEffect(() => {
        handleProjectSelection()
    },[selectedProjectId])

    const handleCloseEditProjectName = () => {
        setProjectTitleEditMode(false)
    }
    
    const handleEditProjectName = () => {
        setProjectTitleEditMode(true)
    }
    
    const handleSingleClickOfProject = () => {
        if (!projectTitleEditMode) {
          setProjectModalOpen(true)
        }
    }
    
    const handleSaveNewProjectName = async () => {
        // TO DO: Save this change to the datastore and re-retrieve project names
        const updatedProject = await saveToFirestore(selectedProject, { name: projectName }, accountId)
        //console.log("updatedProject in front end", updatedProject)
        setSelectedProject({...updatedProject })
        const resultsFromUpdatedProject = await retrieveFromFirestore({accountId: accountId});
        setAccountProjects(resultsFromUpdatedProject)
        setProjectTitleEditMode(false)
    }
    
    const handleOpenProjectModal = () => {
        //console.log("Open project modal, current value is ", projectModalOpen)
        setProjectModalOpen(!projectModalOpen)
    }
    
    const handleDeleteProject = async (projectToDelete) => {
        // Delete project in firestore
        //console.log("handleDeleteProject", projectToDelete)
        try {
          const result = await deleteProject(projectToDelete,accountId)
          //console.log("result of deletion", result)
          // Reretrieve projects for front end
          const projectsAfterDeletion = await retrieveFromFirestore({ accountId: accountId })
          
          // Set view locally
          setAccountProjects(projectsAfterDeletion)
          setSelectedProject(projectsAfterDeletion[0])
          setProjectModalOpen(false)
          // Update URL and selectedProjectId which will trigger a rerender of data
          const newPathname = location.pathname.replace(selectedProjectId, projectsAfterDeletion[0].id);
          navigate(newPathname); 
          return { success: true }
        } catch (error) {
          console.error('Error deleting project:', error);
          return undefined
        }
    }
    
    const handleNewProject = async (newProjectName, newDescription) => {
        // Create new project in firestore
        const newProjectCreated = await newProject({ user: auth.currentUser, newValues: { name: newProjectName, description: newDescription }, accountId: accountId })
        //console.log("newProjectCreated in front end", newProjectCreated)
        // Reretrieve projects for front end
        const reretrievedProjects = await retrieveFromFirestore({accountId: accountId});
        //console.log("reretrievedProjects", reretrievedProjects)
        // Set view locally
        setAccountProjects(reretrievedProjects)
        setSelectedProject(newProjectCreated)
        // setProjectModalOpen(false)
        // Update URL and selectedProjectId which will trigger a rerender of data
        // No longer required as new flow creates the project, and performs a switch at a later point
        // const newPathname = location.pathname.replace(selectedProjectId, newProjectCreated.id);
        // navigate(newPathname);
        return newProjectCreated
    }
    
    const handleSwitchSelectedProject = async (projectSelection, pathname = null) => {
        // Set view locally
        setSelectedProject(projectSelection);
        setProjectModalOpen(false);

        let newPathname = "";

        if (!pathname) {
          // Update URL and selectedProjectId which will trigger a rerender of data
          newPathname = location.pathname.replace(selectedProjectId, projectSelection.id);
        } else if (pathname) {
          newPathname = pathname;
        }
        
        // Check if on the boards page
        if (newPathname.includes('/tags/b/')) {
          // Retrieve boards
          const boards = await retrieveBoardsFromFirestore({accountId: accountId, projectId: projectSelection.id});
          if (boards.length > 0) {
            // Replace the boardId in the URL with the first board's id
            const parts = newPathname.split('/');
            const boardIdIndex = parts.indexOf('b') + 1;
            parts[boardIdIndex] = boards[0].id;
            newPathname = parts.join('/');
          }
        }
      
        navigate(newPathname);
        // Set selectedProject against user account
        await setNewSelectedProject(auth.currentUser._delegate.uid, projectSelection.id)
        // Set updated timestamp against project to show it has been recently accessed
        await saveToFirestore(projectSelection, { updated: new Date() }, accountId)
        // Re-retrieve projects
        const newProjectsSortOrder = await retrieveFromFirestore({accountId: accountId});
        setAccountProjects(newProjectsSortOrder)
    };
    
    useEffect(() => {    
        //console.log("selectedProject ", selectedProject)
        setProjectName(selectedProject && selectedProject.name)
    },[selectedProject])
    
    useEffect(() => {    
        //console.log("accountProjects ", accountProjects)
    },[accountProjects])
    
    useEffect(() => {
        // TO DO: fetch account projects for account that user has access to
        const fetchData = async () => {
          try {
            const data = await retrieveFromFirestore({accountId: accountId});
            return data;
          } catch (error) {
            console.error('Error fetching accounts from Firestore:', error);
            throw error; // Rethrow the error so that the outer catch block can handle it
          }
        };
    
        const getData = async () => {
          if (!running) {
            try {
              setRunning(true);
              const data = await fetchData();
              if (data.length == 0) {
                //console.log("No projects found")
                setRunning(false);
                return;
              }
              setAccountProjects(data);
              // Filter array of data where the value of data.id is equal to selectedProjectId
              //console.log("Attempting to filter using selectedProjectId", selectedProjectId)
              const filteredData = data.filter(item => item.id === selectedProjectId);
              //console.log("filteredData based on selectedProjectId", filteredData);
              setSelectedProject(filteredData[0]);
              setRunning(false);
            } catch (error) {
              // Handle the error if needed
              //console.log("error fetching projects", error);
              setRunning(false);
            }
          }
        };
    
        if (accountProjects.length == 0) {
          getData();
        }
    },[])

    const value = {
        accountProjects,
        setAccountProjects,
        projectTitleEditMode,
        setProjectTitleEditMode,
        projectModalOpen,
        setProjectModalOpen,
        selectedProject,
        setSelectedProject,
        projectName,
        setProjectName,
        running,
        setRunning,
        handleCloseEditProjectName,
        handleEditProjectName,
        handleSingleClickOfProject,
        handleSaveNewProjectName,
        handleOpenProjectModal,
        handleDeleteProject,
        handleNewProject,
        handleSwitchSelectedProject
    };

  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useProjects() {
  return useContext(ProjectsContext);
}