import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';

export default function ConfirmProjectDeletion({ 
  open, 
  setOpen, 
  handleDeleteProject, 
  projectId,
  setShowSnackbar,
  setSnackbarSeverity,
  setSnackbarMsg,
  setVariantSetting
}) {

  const handleDeleteProjectClick = async () => {
    const result = await handleDeleteProject(projectId)
    console.log("result of deletion", result)
    // If result returns an error then show error, otherwise show success msg
    if (result === undefined) {
      console.log("result is undefined")
      setShowSnackbar(true)
      setOpen(false)
      setVariantSetting('solid')
      setSnackbarSeverity('danger')
      setSnackbarMsg('Error deleting project. Please try again later.')
    } else {
      setOpen(false)
      setShowSnackbar(true)
      setSnackbarSeverity('success')
      setSnackbarMsg('Project deleted successfully.')
    }
  }

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Confirm project deletion</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this project, all project documents and connections? This cannot be undone.
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="danger" onClick={handleDeleteProjectClick}>Delete</Button>
            <Button variant="solid" color="success" onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}