import React, { useState, useEffect } from 'react';
import { Stack, Box, ModalClose } from '@mui/joy';
import Grid from '@mui/joy/Grid';
import { useNotes } from '../../../contexts/notesContext';
import { useAiPane } from '../../../contexts/aiPaneContext';
import { useConnectionsPane } from '../../../contexts/connectionsPaneContext';
import Uploads from '../../MessagesPane/Uploads/Images';
import { useAuth } from '../../../../authContext';
import GoBack from '../../Buttons/GoBack';
import ModalWithSidePane from '../../Layouts/ModalWithSidePane';
import ModalEditorFrame from '../../Layouts/ModalEditorFrame';
import Sidepane from '../../Sidepane';
import NotesPaneHeaderEdit from '../../MessagesPane/NotesPaneHeaderEdit';

export default function UploadEditor() {
    const { selectedNote, noteEditorOpen, setNoteEditorOpen, handleSave, checkNoteEditPermissions } = useNotes();
    const [allowEditing, setAllowEditing] = useState(false);
    const [ sidePaneOpen, setSidePaneOpen ] = React.useState(false)
    const { currentUser } = useAuth();

    useEffect(() => {
      if (currentUser && selectedNote) {
        const canEdit = checkNoteEditPermissions({
          content: selectedNote,
          currentUser,
          isEditorOpen: noteEditorOpen
        });
        setAllowEditing(canEdit);
      }
    }, [currentUser, selectedNote, noteEditorOpen]);

    return (
      <ModalEditorFrame setOpen={setNoteEditorOpen} open={noteEditorOpen}>
        <ModalWithSidePane
          sidePaneOpen={sidePaneOpen} 
          setSidePaneOpen={setSidePaneOpen} 
          sideWidth='20%' 
          SideContent={<Sidepane type={selectedNote.type} noteToDisplay={selectedNote} />} 
          MainContent={
            <>
              <NotesPaneHeaderEdit 
                sidePaneOpen={sidePaneOpen} 
                setSidePaneOpen={setSidePaneOpen} 
                allowEditing={allowEditing} 
                noteToDisplay={selectedNote}
              />
              <Box>
                <Uploads mode="edit" content={selectedNote} />
              </Box>
            </>
          }
        />
      </ModalEditorFrame>
    );
}
