import * as React from 'react';
import Chip from '@mui/joy/Chip';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import Toggler from '../Toggler';
import Typography from '@mui/joy/Typography';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import LabelIcon from '@mui/icons-material/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import CableIcon from '@mui/icons-material/Cable';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import StyleIcon from '@mui/icons-material/Style';
import { BASE_PRIVATEAPP_URL } from './../../../../redirects/index';
import { useAuth } from '../../../../authContext';  
import { useBoards } from '../../../contexts/boardsContext';
import { useNotes } from '../../../contexts/notesContext';
import { useLoading } from '../../../contexts/loadingContext';
import { useTags } from '../../../contexts/tagsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/joy';
import { createMissingDefaultTagBoard } from '../../../database/tags-db';
import TagBoards from '../Components/TagBoards';
import SwitchProject from '../Components/SwitchProject';
import Search from '../../Search';
import BrowseNotes from '../../Modals/BrowseNotes';

export default function MobileNav({closeSidebar}) {

  const navigate = useNavigate();
  const { accountId, selectedProjectId, boardId } = useParams() 
  const { currentUser } = useAuth();
  const { boards, setBoards, handleFetchBoards } = useBoards();
  const { handleCreateNewNote } = useNotes();
  const { running, setRunning } = useLoading();
  const [ value, setValue ] = React.useState();
  const [ researchModal, setResearchModal ] = React.useState(false);

  const handleClick = () => {
    console.log("Open notes")
    setResearchModal(true);
  };

  const handleRedirect = (url) => {
    navigate(url);
  }

  const handleAddNote = () => {
    //console.log("handleAddNote", BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId)
    // Create a new note
    handleCreateNewNote()
    // Navigate to the notes page
    navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId);
  }

  const handleAddBoard = async () => {
    //console.log("handleAddBoard")
    // Create new board, and then 
    const newBoard = await createMissingDefaultTagBoard({ user: currentUser, accountId: accountId, projectId: selectedProjectId })
    //console.log("newBoard created", newBoard);
    // Refetch the boards to update navigation etc
    handleFetchBoards()
    // Redirect to the new board
    navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/tags/b/' + newBoard.id);     
  }

  return (
    <List
      size="sm"
      sx={{ '--ListItem-radius': 'var(--joy-radius-sm)', '--List-gap': '4px' }}
    >
      
      <SwitchProject />
      <Search value={value} setValue={setValue} />
      <ListItem nested>
        <Toggler
          renderToggle={({ open, setOpen }) => (
            <ListItemButton>
              <AssignmentRoundedIcon />
              <ListItemButton onClick={() => handleClick() }>
                <Typography level="title-sm">Research</Typography>
              </ListItemButton>
              <IconButton aria-label="Add" size="sm" variant="plain" color="neutral" onClick={handleAddNote}>
                <Add />
              </IconButton>
              <BrowseNotes open={researchModal} setOpen={setResearchModal} setSubMenu={closeSidebar} />
            </ListItemButton>
          )}
        >
          <List sx={{ gap: 0.5 }}>
            <ListItem sx={{ mt: 0.5 }}>
              <ListItemButton>All</ListItemButton>
            </ListItem>
            <ListItem sx={{ mt: 0.5 }}>
              <ListItemButton>Published</ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>Highlights</ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => { handleRedirect(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/bin');} }><DeleteIcon />Bin</ListItemButton>
            </ListItem>
          </List>
        </Toggler>
      </ListItem>
      <ListItem nested>
          <Toggler
            renderToggle={({ open, setOpen }) => (
              <ListItemButton onClick={() => { setOpen(!open); } }>
                <MenuBookIcon />
                <ListItemContent>
                  <Typography level="title-sm">Define</Typography>
                </ListItemContent>
                <IconButton aria-label="Add" size="sm" variant="plain" color="neutral">
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </IconButton>
              </ListItemButton>
            )}
          >
            <List sx={{ gap: 0.5 }}>
              <ListItem sx={{ mt: 0.5 }}>
                <ListItemButton onClick={() => { handleRedirect(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/personas');} }>Personas</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => { handleRedirect(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/journeys');} }>Journeys</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => { handleRedirect(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/features');} }>Features</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => { handleRedirect(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/ia');} }>Navigations</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => { handleRedirect(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/pages');} }>Pages &amp; Content</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton disabled>User Flows (coming soon)</ListItemButton> 
                {/* /*  onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/flows');} */ }
              </ListItem>
            </List>
          </Toggler>
        </ListItem>
        { boards && boards.length === 1 ?
            <ListItem>
              { boards[0] && boards[0].title &&
              <ListItemButton onClick={() => { handleRedirect(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/tags/b/' + boards[0].id ) ;} }>
                <StyleIcon />
                <ListItemContent sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography level="title-sm">{boards[0] && boards[0].title && boards[0].title}</Typography>
                </ListItemContent>
                <IconButton aria-label="Add" size="sm" variant="plain" color="neutral">
                  <Add onClick={handleAddBoard} />
                </IconButton>
              </ListItemButton>
              }
            </ListItem>
          : boards.length > 1 
            &&
            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => { setOpen(!open); }}>
                    <LabelIcon />
                    <ListItemContent>
                      <Typography level="title-sm">Tag Boards</Typography>
                    </ListItemContent>
                    <IconButton aria-label="Add" size="sm" variant="plain" color="neutral">
                      <KeyboardArrowDownIcon
                        sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                      />
                    </IconButton>
                  </ListItemButton>
                )}
              >
                <TagBoards handleRedirect={handleRedirect} boards={boards} />
              </Toggler>
            </ListItem>
        }
        <ListItem>
          <ListItemButton onClick={() => { handleRedirect(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/connections') }}><CableIcon />Connections</ListItemButton>
        </ListItem>
    </List>
  );
}